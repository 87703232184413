import React, { useState, useEffect, useRef } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import success from "../imgs/success-cropped.svg";
import axios from "axios";
import profile from "../imgs/profile-cropped.svg";
import Card from "./OptionCard";
import Header from "./Header";
import "./Multistep.css";
import api from "./api";

export default function MultistepNewVersion() {
  const stepperRef = useRef(null);
  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [oldData, setOldData] = useState([]);
  const [file_id, setFileId] = useState("");
  const [fetched, setFetched] = useState(false);
  const [readyForUpload, setReadyForUpload] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [titleUpload, setTitleUpload] = useState(
    "Upload any list in csv or xlsx format"
  );
  const [client_id, setClientId] = useState(localStorage.getItem("client_id"));
  const [client_name, setClientName] = useState(
    localStorage.getItem("firstName")
  );
  const [depOptions, setDepOptions] = useState({});
  const [departmentStep, setDepartmentStep] = useState(false);
  const [bgColors, setBgColors] = useState({});
  const [cold_calling, setColdCalling] = useState(false);
  const [texting, setTexting] = useState(false);
  const [description, setDescription] = useState("");
  const [skip_source, setSkipSource] = useState("");
  const [source, setSource] = useState("");
  const [list_pull_date, setListPullDate] = useState("");
  const [skip_date, setSkipDate] = useState("");
  const [file_name, setFileName] = useState("");
  const [fileTags, setFileTags] = useState([]);
  const [previous_version, setPreviousVersion] = useState("");
  const [current_version, setCurrentVersion] = useState(1);
  const [campaign_id, setCampaign] = useState(() => {
    const savedCamp = sessionStorage.getItem("camapaign_id");
    return savedCamp ? JSON.parse(savedCamp) : "";
  });
  const [upload_date, setUploadDate] = useState("");
  const [deduplicated, setDeduplicated] = useState(false);
  const [duplicates_removed, setDuplicatesRemoved] = useState(0);
  const [savings, setSavings] = useState(0.0);
  const [file_size, setFileSize] = useState(0.0);
  const [skiptraced_by_res, setSkiptracedByRes] = useState(false);
  const [uploaded_file, setUploadedFile] = useState(null);
  const [file_type, setFileType] = useState("CSV");
  const [original_name, setOriginalName] = useState("");
  const [progress, setProgress] = useState(0);
  const interval = useRef(null);
  const [activeIdx, setActiveIdx] = useState(0);
  //total steps
  const totalIdx = 5;
  const skipSources = [
    { name: "Kind Skip" },
    { name: "Batch Skip" },
    { name: "Prop Stream" },
    { name: "REI Sift" },
    { name: "Other" },
  ];
  //get file Id from state to get all information about the older version
  useEffect(() => {
    let fileId = "";
    location.state.data.forEach((file) => {
      if (file.file_name === location.state.selectedFile) {
        fileId = file.file_id;
      }
    });
    const fetchData = async () => {
      const formDataId = new FormData();
      formDataId.append("client_id", client_id);
      formDataId.append("file_id", fileId);

      try {
        const response = await api.post("user/file", formDataId, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("old", response.data);
        //set the information that won't be changed in the new version of the list(source,pull-date)
        //get the current version and increment it
        setOldData(response.data);
        setCampaign(response.data.campaign_id);
        setCurrentVersion(response.data.current_version + 1);
        setSource(response.data.source);
        setListPullDate(response.data.list_pull_date);
        setPreviousVersion(response.data.file_id);
        //get tags associated with this file
        const formDataFile = new FormData();
        formDataFile.append("file_id", fileId);
        const response2 = await api.post("file/file-tags", formDataFile, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const filetags = response2.data.data.map((file) => file.tag_id);
        console.log("Response 2:", filetags);
        setFileTags(filetags);
        setFetched(true);
      } catch (error) {
        console.error("There was an error fetching the files!", error);
      }
      console.log("campaign", sessionStorage.getItem("campaign_id"));
    };
    fetchData();
  }, [location.state.data]);
  //
  useEffect(() => {
    console.log(current_version);
    console.log(previous_version);
  }, [oldData]);
  //handle multiple department selection
  useEffect(() => {
    console.log(depOptions);
    if (Object.keys(depOptions).length === 0) {
      setDepartmentStep(false);
    } else {
      for (const option in depOptions) {
        if (depOptions[option]) {
          console.log("Setting", option, "to true");
          switch (option) {
            case "Cold Calling":
              setColdCalling(true);
              setDepartmentStep(true);
              break;
            case "Texting":
              setTexting(true);
              setDepartmentStep(true);
              break;
            default:
              break;
          }
        }
      }
    }
  }, [depOptions]);
  //upload data to create a file
  useEffect(() => {
    const uploadFormData = async () => {
      const requiredFields = {
        client_id,
        cold_calling,
        texting,
        source,
        list_pull_date,
        skip_source,
        skip_date,
        current_version,
        upload_date,
        deduplicated,
        duplicates_removed,
        savings,
        file_size,
        skiptraced_by_res,
        campaign_id,
        file_type,
        original_name,
        description,
      };
      for (const [key, value] of Object.entries(requiredFields)) {
        if (value === "" && key != "description") {
          console.log(`Error: ${key} is missing or empty`);
          return;
        }
      }
      const formData = new FormData();
      formData.append("client_id", client_id);
      formData.append("cold_calling", cold_calling);
      formData.append("texting", texting);
      formData.append("source", source);
      formData.append("list_pull_date", list_pull_date);
      formData.append("skip_source", skip_source);
      formData.append("skip_date", skip_date);
      formData.append("previous_version", previous_version);
      formData.append("current_version", current_version);
      formData.append("upload_date", upload_date);
      formData.append("deduplicated", deduplicated);
      formData.append("duplicates_removed", duplicates_removed);
      formData.append("savings", savings);
      formData.append("file_size", file_size);
      formData.append("skiptraced_by_res", skiptraced_by_res);
      formData.append("campaign_id", campaign_id);
      formData.append("uploaded_file", uploaded_file);
      formData.append("file_type", file_type);
      formData.append("original_name", original_name);
      formData.append("description", description);
      console.log(description);
      const fileName = `${client_name}${cold_calling ? "-CC" : ""}${
        texting ? "-Texting" : ""
      }-${source}-${list_pull_date}-${skip_source}-${skip_date}-${original_name}-v${current_version}.${file_type}`;
      setFileName(fileName);
      console.log("File name:", fileName);
      formData.append("file_name", fileName);
      let _val = progress;
      try {
        setProgress(1);
        const response = await api.post("file/file-create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          //simulate file upload
          onUploadProgress: function (progressEvent) {
            const total = progressEvent.total;
            const uploaded = progressEvent.loaded;
            _val = Math.round((uploaded * 100) / total);
            if (_val >= 98) {
              _val = 98;
              clearInterval(interval.current);
              interval.current = null;
            }
            setProgress(_val);
          },
        });
        console.log("Upload successful:", response.data);
        console.log(response.data.file_id);
        const formDataFile = new FormData();
        formDataFile.append("client_id", client_id);
        formDataFile.append("file_id", response.data.file_id);
        formDataFile.append("tags", fileTags.join(","));
        setProgress(99);
        clearInterval(interval.current);
        interval.current = null;
        if (fileTags.length != 0) {
          const response2 = await api.post("file/add-file-tag", formDataFile, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          console.log(response2);
          clearInterval(interval.current);
          interval.current = null;
          setUploadSuccessful(true);
        }
      } catch (error) {
        console.error("Upload failed:", error);
      }
    };
    if (fetched) {
      uploadFormData();
    }
  }, [readyForUpload]);
  //Handle Upload file
  const handleFileUpload = (file) => {
    // TODO: adjust file types + excel files because they cause an error
    const validTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!validTypes.includes(file.type)) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "File type not supported. Please upload a CSV or Excel file.",
        life: 3000,
      });
      return;
    }
    if (file.type === "text/csv") {
      setFileType("csv");
    } else if (file.type === "application/vnd.ms-excel") {
      setFileType("xls");
    } else {
      setFileType("xlsx");
    }

    setUploadedFile(file);
    const nameTrimmed = file.name.split(".").slice(0, -1).join(".");
    setOriginalName(nameTrimmed);
    setFileSize(file.size);
    setUploadDate(new Date().toISOString().split("T")[0]);
    console.log(file);
    setTitleUpload("Your file is uploaded successfully");
    setShowSuccessMessage(true);
  };
  //handle close button
  const closeMessage = () => {
    setShowSuccessMessage(false);
  };
  //Warning in case clicking next without entering required data
  const showWarn = () => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: "Please Enter Required Data To Proceed",
      life: 3000,
    });
  };
  //Click on go to database or on filename
  const handleClick = () => {
    navigate("/database");
  };
  // Handle clicking on next
  const handleNextClick = (state, ref) => {
    if (state) {
      ref.current.nextCallback();
      setActiveIdx((prev) => prev + 1);
    } else {
      showWarn();
    }
  };
  //Handle changes skip source card
  const handleDepChange = (card, choice) => {
    if (card == "SkipSource") {
      setSkipSource(choice);
    }
  };
  //handle date change in skip date card
  const handleCalendarInput = (card, choice) => {
    if (card === "Skip Date") {
      console.log(choice);
      if (new Date(choice) < new Date(list_pull_date)) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail:
            '"Skip Date cannot be before List Pull Date. Please enter again.',
          life: 3000,
        });
        setSkipDate(null);
      } else {
        setSkipDate(choice);
      }
    }
  };

  return (
    <div>
      <Header
        username={client_name}
        profileLink="/options"
        profileImage={profile}
        upload={true}
      />
      {showSuccessMessage && (
        <Dialog
          header="Success"
          visible={showSuccessMessage}
          onHide={closeMessage}
        >
          <div className="flex flex-col justify-center">
            <div className="alert alert-success">
              File {uploaded_file.name} uploaded successfully!
            </div>
          </div>
        </Dialog>
      )}
      <div className="flex flex-col min-h-svh gap-12">
        <Stepper ref={stepperRef} style={{ flexBasis: "50rem" }} linear>
          <StepperPanel header="Upload File">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={0}
                  totalIdx={totalIdx}
                  title={titleUpload}
                  upload={true}
                  image={success}
                  uploadedFile={uploaded_file}
                  onFileUpload={handleFileUpload}
                />
              </div>
            </div>
            <Toast ref={toast} />
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick(uploaded_file, stepperRef)}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Department">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={1}
                  totalIdx={totalIdx}
                  title={
                    "For which department do you intend to send this list?"
                  }
                  choices={["Cold Calling", "Texting"]}
                  onDescriptionChange={setDescription}
                  all={true}
                  selections={setDepOptions}
                  setBgColors={setBgColors}
                  bgColors={bgColors}
                />
              </div>
            </div>
            <Toast ref={toast} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick(departmentStep, stepperRef)}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Skiptracing Service">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={2}
                  dropMenu={true}
                  skip={true}
                  totalIdx={totalIdx}
                  title={"What service do you use for skiptracing?"}
                  choices={["Kind Skip", "Batch Skip", "Prop Stream"]}
                  vertical={true}
                  sources={skipSources}
                  setSkipSource={setSkipSource}
                  skipsource={skip_source}
                  onListSource={handleDepChange}
                  setBgColors={setBgColors}
                  bgColors={bgColors}
                />
              </div>
            </div>
            <Toast ref={toast} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick(skip_source, stepperRef)}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Skiptracing Date">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={3}
                  totalIdx={totalIdx}
                  skipDate={true}
                  title={"When did you last skiptrace that list?"}
                  calendar={true}
                  onCalendarInput={handleCalendarInput}
                />
              </div>
            </div>
            <Toast ref={toast} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick(skip_date, stepperRef);
                  if (skip_date) {
                    setReadyForUpload(true);
                  }
                }}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Final Steps">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  progress={progress}
                  activeIdx={4}
                  totalIdx={totalIdx}
                  uploadCompleted={true}
                  title={
                    uploadSuccessful
                      ? "Congratulations! Your filename is set up and ready to download. It will be safe in your account forever and used as a reference for your new lists."
                      : "Please wait your file is uploading"
                  }
                  fileName={uploadSuccessful ? file_name : "loading"}
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <Button
                label="Go to Database"
                disabled={!uploadSuccessful}
                className="bg-amber-200 text-center py-2 m-4 px-5 rounded-2xl border-none text-[20px] text-black font-bold hover:bg-[#009ccf] hover:text-white"
                onClick={handleClick}
              />
            </div>
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => stepperRef.current.prevCallback()}
              />
            </div>
          </StepperPanel>
        </Stepper>
      </div>
    </div>
  );
}
