import React, { useState, useRef } from "react";
import WalkthroughCard from "./WalkthroughCard";
import profile from "../imgs/profile-cropped.svg";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { InlineWidget } from "react-calendly";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "./Custom.css";
import { pullList } from "./api";
import Header from "./Header";
import list from "../imgs/file-check-svgrepo-com-cropped.svg";
import priceIcon from "../imgs/price-svgrepo-com.svg";
export default function ListCriteria() {
  const warning = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [calendlyPanel, setCalendlyPanel] = useState(0);
  const [county, setCounty] = useState("");
  const [visible, setVisible] = useState(false);
  const [price, setPrice] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [records, setRecords] = useState("");
  const [criteria, setCriteria] = useState("");
  const [buyBox, setBuyBox] = useState("");
  const [listDetails, setListDetails] = useState(false);
  const [emptyValueIndices, setEmptyValueIndices] = useState([]);
  const [listReceived, setListReceived] = useState(false);
  const inputs = [
    {
      name: "Number of records",
      placeholder: "e.g. 100",
      type: "Integer",
      key: "",
    },
    {
      name: "Zip Code",
      placeholder: "e.g. 12345-1234 or 12345",
      type: "Text",
      key: "alphanum",
    },
    {
      name: "County, State",
      placeholder: "e.g. Marion County, Indiana",
      type: "Text",
      key: "alphanum",
    },
    {
      name: "Criteria",
      placeholder: "e.g. Financial Distress, 10 years owned",
      type: "Text",
      key: "alphanum",
    },
    {
      name: "Buy Box",
      placeholder: "e.g. Property type, Property Size",
      type: "Text",
      key: "alphanum",
    },
  ];

  //send list criteria to data management
  const UploadCriteria = async () => {
    const formData = new FormData();
    formData.append("client_id", localStorage.getItem("client_id"));
    formData.append("number_of_records", records);
    formData.append("zip_code", zipCode);
    formData.append("county", county);
    formData.append("criteria", criteria);
    formData.append("buy_box", buyBox);
    const response = pullList(formData);
    if ((await response).success) {
      console.log("done");
    }
    setListReceived(true);
  };

  const confirmList = () => {
    // Regular expression to match the format number-number

    let emptyFieldNames = emptyValueIndices
      .map((val) => inputs[val].name)
      .join(", ");
    if (emptyFieldNames !== "") {
      warning.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `Please fill ${emptyFieldNames} to proceed`,
        life: 3000,
      });
      setEmptyValueIndices([]);
      return false;
    }
    const regex = /^\d{5}(-\d{4})?$/;
    if (!regex.test(zipCode) && zipCode !== "") {
      warning.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please Enter a valid Zip Code",
        life: 3000,
      });
      setEmptyValueIndices([]);
      return false;
    } else {
      setEmptyValueIndices([]);
      if (listDetails) {
        setPrice(true);
      }
    }
  };

  return (
    <div className="mb-4">
      <Toast ref={warning} />
      <Header
        username={localStorage.getItem("firstName")}
        profileLink="/options"
        profileImage={profile}
        options={activeIndex !== 1}
        setup={activeIndex === 1}
        title={"Request a list"}
      />
      {!listReceived && activeIndex === 1 && (
        <h2 className="flex  p-3 text-black text-lg font-bold">
          Please specify the criteria you would like to include in your list?
        </h2>
      )}
      <div className=" m-auto justfy-content-center height-0">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          className="justfy-content-center height-0"
        >
          <TabPanel header="">
            <div className="mt-[5%] items-center justify-center  mx-auto min-w-min">
              <p className="m-1 text-black text-[20px]">
                Share what you're looking for, and we'll help you determine the
                best criteria to include in your list. Whether you have a clear
                idea of what you want or need some guidance, we're here to
                assist you every step of the way.
                <br />
                <b>
                  Can you specify the criteria you would like to include in your
                  list?
                </b>
              </p>
              <div className="flex  text-center mt-4 flex justify-content-center space-x-2 m-2 p-2">
                <Button
                  className="rounded-full hover:bg-[#009ccf] bg-[#8de0ff] hover:text-white  text-center text-black font-semibold w-1/4 h-[50px] flex items-center justify-center"
                  onClick={() => setActiveIndex(1)}
                >
                  Yes
                </Button>
                <Button
                  className="rounded-full  hover:bg-[#009ccf] bg-[#8de0ff] hover:text-white  text-center text-black font-semibold w-1/4 h-[50px] flex items-center justify-center"
                  onClick={() => setVisible(true)}
                >
                  No
                </Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="">
            {!listReceived ? (
              <>
                <WalkthroughCard
                  description={true}
                  alone={true}
                  question={<span className="font-bold"> List Criteria </span>}
                  inputs={inputs}
                  functions={[
                    setRecords,
                    setZipCode,
                    setCounty,
                    setCriteria,
                    setBuyBox,
                  ]}
                  final={true}
                  confirm={confirmList}
                  emptyValueIndices={emptyValueIndices}
                  finalButton={"Confirm"}
                  uploadSuccessful={true}
                  destination={"/options"}
                  onSet={setListDetails}
                />
              </>
            ) : (
              <WalkthroughCard
                final={true}
                question={
                  <span>
                    Your criteria have been saved. Our Data Management
                    Department will pull the requested list for you.They will
                    contact you to arrange payment, and you will receive an
                    email once the list is ready.
                  </span>
                }
                destination={"/options"}
                finalButton={"Go Home"}
                uploadSuccessful={true}
                img={list}
              />
            )}
          </TabPanel>
        </TabView>

        <Dialog
          className="dialog calendly"
          header="Contact us"
          visible={visible}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
            setCalendlyPanel(0);
          }}
          style={{ width: "50vw", color: "#23455B" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <TabView
            activeIndex={calendlyPanel}
            onTabChange={(e) => setCalendlyPanel(e.index)}
          >
            <TabPanel header="" disabled>
              <div className="flex justify-center items-center m-2 mb-4">
                <img
                  src={
                    "https://res-data-space.fra1.cdn.digitaloceanspaces.com/icons/res-logo-removebg.png"
                  }
                  alt="logo"
                  className='className="w-[100px] h-[100px] flex justify-center m-[70] p-[40]"'
                />
              </div>
              <p className="m-0">
                To provide you with personalized assistance, we can schedule a
                meeting. Choose a date and time that works best for you, and
                we'll help you define the criteria for your list.
              </p>

              <div className="flex justify-center items-center m-2">
                <Button
                  className="bg-[#23455B] rounded-full p-2 px-4 hover:bg-[#168EC2] text-white font-semibold mt-2s"
                  onClick={() => {
                    setCalendlyPanel(1);
                  }}
                >
                  Schedule a meeting
                </Button>
              </div>
            </TabPanel>
            <TabPanel className="">
              <InlineWidget url="https://calendly.com/d/cmj8-x4q-zzd/customer-success-data-discussion" />
            </TabPanel>
          </TabView>
        </Dialog>
        <Dialog
          visible={price}
          modal
          onHide={() => {
            if (!price) return;
            setPrice(false);
          }}
          content={({ hide }) => (
            <div
              className="flex flex-column px-8 py-5 gap-4"
              style={{ borderRadius: "12px", backgroundColor: "white" }}
            >
              <div className="flex justify-center items-center m-2 mb-4">
                <img
                  src={priceIcon}
                  alt="logo"
                  className='className="w-[100px] h-[100px] flex justify-center m-[70] p-[40]"'
                />
              </div>
              <h2 className="text-center">
                <span className="font-bold text-black">
                  Estimated Total for Your Requested List: ${0.09 * records}
                </span>
              </h2>
              <p className="m-0 text-center">
                Would you like to proceed with your request?
              </p>

              <div className="flex justify-center align-items-center gap-2">
                <Button
                  className="bg-[#23455B] rounded-full p-2 px-4 hover:bg-[#168EC2] text-white font-semibold mt-2s"
                  onClick={(e) => {
                    UploadCriteria();
                    hide(e);
                  }}
                >
                  Yes
                </Button>

                <Button
                  className="bg-[#23455B] rounded-full p-2 px-4 hover:bg-[#168EC2] text-white font-semibold mt-2s"
                  onClick={(e) => hide(e)}
                >
                  No
                </Button>
              </div>
            </div>
          )}
        ></Dialog>
      </div>
    </div>
  );
}
