import React, { createContext, useContext, useState, useEffect } from "react";
import useSessionTimeout from "../SessionTimeOut";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const savedAuth = localStorage.getItem("isAuthenticated");
    return savedAuth === "true";
  });

  const [role, setRole] = useState(() => {
    const savedRole = localStorage.getItem("role");
    return savedRole;
  });
  const login = (first_name, last_name, client_id, role, email, department) => {
    setIsAuthenticated(true);
    setRole(role);
    localStorage.setItem("isAuthenticated", "true");
    localStorage.setItem("firstName", first_name);
    localStorage.setItem("lastName", last_name);
    localStorage.setItem("email", email);
    localStorage.setItem("client_id", client_id);
    localStorage.setItem("role", role);
    localStorage.setItem("department", department);
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("client_id");
    localStorage.removeItem("role");
  };
  const handleLogout = () => {
    logout();
    console.log("logging out");
    localStorage.setItem(
      "logoutMessage",
      "Your session has expired. Please log in again."
    );
    window.location.href = "/signin";
  };

  useEffect(() => {
    const savedAuth = localStorage.getItem("isAuthenticated");
    if (savedAuth === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  useSessionTimeout(handleLogout, role, isAuthenticated, 1200000);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, role }}>
      {children}
    </AuthContext.Provider>
  );
};
