import React, { useRef, useState } from "react";
import { Heading } from "@primer/react";
import { Menu } from "primereact/menu";
// import { Carousel } from "primereact/carousel";
import Carousel from "./Carousel";
import "primeicons/primeicons.css";
import logo from "../imgs/logo.png";
import buildingImage from "../imgs/landing-image.svg";
import newSectionImage from "../imgs/3.svg";
import demo from "../imgs/demoLanding.mp4";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

function LandingScreen() {
  const navigate = useNavigate();
  const menu = useRef(null);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { isAuthenticated, role, logout } = useAuth();

  const handleClick = () => {
    navigate("/signin");
  };

  const handleClickRegister = () => {
    navigate("/register");
  };
  const handleLogout = () => {
    logout();
    navigate("/");
  };
  const items = isAuthenticated
    ? [
        {
          label: "Home",
          icon: "pi pi-home",
          command: () => {
            role === "user" ? navigate("/options") : navigate("/data-options");
          },
        },
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          command: () => {
            handleLogout();
          },
        },
      ]
    : [
        {
          label: "Sign In",
          icon: "pi pi-sign-in",
          command: () => {
            handleClick();
          },
        },
        {
          label: "Register",
          icon: "pi pi-user-plus",
          command: () => {
            handleClickRegister();
          },
        },
      ];
  const carouselItems = [
    {
      id: 1,
      content: (
        <div className="grid md:grid-cols-2 gap-12 p-10 text-left">
          <div className="max-w-full mx-auto my-auto justify-center">
            <h1 className=" lg:text-7xl md:text-5xl sm:text-4xl xs:text-3xl p-2 font-light leading-tight max-w-full">
              Your properties, our responsibility.
            </h1>
            <h3 className="lg:text-2xl md:text-xl sm:text-lg xs:text-base italic p-2 font-light">
              Upload your Real-Estate Lists and have clean data forever using
              ResVA’s unique data storage.
            </h3>
          </div>

          <img src={buildingImage} alt="Buildings" className="mx-auto w-3/4" />
        </div>
      ),
    },
    {
      id: 1,
      content: (
        <div
          key="2"
          className="flex flex-col md:flex-row items-center justify-between text-black p-10 w-full"
        >
          <div className="max-w-full mx-auto my-auto justify-center">
            <h2 className="lg:text-6xl md:text-4xl sm:text-3xl xs:text-2xl font-light">
              Say Hello to Clean Data Forever
            </h2>
            <p className="lg:text-xl md:text-lg sm:text-base xs:text-base font-light mt-5">
              Cluttered and disorganized data will hinder your productivity. We
              offer you a unique solution right at your fingertips, where you
              upload the list and leave the rest.
            </p>
            <div className="flex justify-center mt-5">
              <button
                className="px-4 py-2 flex h-12 items-center text-sm font-sans font-bold leading-snug text-black rounded-full bg-white shadow-lg transform hover:scale-105 hover:shadow-xl m-2"
                onClick={handleClickRegister}
              >
                Register Now
              </button>
            </div>
          </div>
          <img src={newSectionImage} alt="New Section" className="w-2/5 mt-2" />
        </div>
      ),
    },
  ];
  const itemTemplate = (item) => {
    return (
      <div className=" mx-auto  h-full flex items-center justify-center">
        {item.content}
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full bg-gradient-to-b from-[#009CCF] to-[#8DE0FF] text-center overflow-x-hidden">
        <nav className="relative flex flex-wrap items-center justify-between px-2 py-3  mb-3 w-full">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start px-3 py-2  m-2">
            <img
              src={logo}
              alt="RESVA Data Solutions"
              className="h-12 rounded-full"
            />

            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={(event) => menu.current.toggle(event)}
            >
              <i className="pi pi-bars"></i>
            </button>
            <Menu model={items} popup ref={menu} />
          </div>

          <div
            className={
              "lg:flex  flex-grow items-center  m-2" +
              (navbarOpen ? " flex justify-end m-2" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              {!isAuthenticated ? (
                <>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex h-12 items-center text-sm font-sans font-bold leading-snug text-black rounded-full bg-white shadow-lg transform hover:scale-105 hover:shadow-xl m-2"
                      href="/register"
                    >
                      <span className="ml-2 mr-2">Register</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex h-12 items-center text-sm font-sans font-bold leading-snug text-black rounded-full bg-white shadow-lg transform hover:scale-105 hover:shadow-xl m-2"
                      href="/signin"
                    >
                      <span className="ml-2 mr-2">Sign In</span>
                    </a>
                  </li>
                </>
              ) : (
                <li className="nav-item">
                  <a
                    className="px-3 py-2 flex h-12 items-center text-sm font-sans font-bold leading-snug text-black rounded-full bg-white shadow-lg transform hover:scale-105 hover:shadow-xl m-2"
                    href={role === "user" ? "/options" : "/data-options"}
                  >
                    <span className="ml-2 mr-2">Home</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </nav>

        <div className="w-screen carousel">
          <Carousel slides={carouselItems} />
        </div>
        <section className="text-black bg-white p-10 w-full text-center">
          <Heading as="h2" className="text-2xl font-light mt-5">
            Quick demo!
          </Heading>
          <div className="flex justify-center items-center mt-5 ">
            <iframe
              width="560"
              height="283.5"
              src={demo}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="rounded-md shadow-2xl"
            ></iframe>
          </div>
        </section>
      </div>
    </>
  );
}

export default LandingScreen;
