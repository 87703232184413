import React, { useState, useEffect, useRef } from "react";
import WalkThroughCard from "./WalkthroughCard";
import Header from "./Header";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Stepper } from "primereact/stepper";
import { createCampaign, updateClientDetails, addClientTags } from "./api";
import { StepperPanel } from "primereact/stepperpanel";
import { useNavigate, useLocation } from "react-router-dom";
import final from "../imgs/trophy.png";
import profile from "../imgs/profile-cropped.svg";

export default function SetUp() {
  const stepperRef = useRef(null);
  const toast = useRef(null);
  const location = useLocation();
  const totalIdx = 7;
  const navigate = useNavigate();
  const client_id = localStorage.getItem("client_id");
  const [keep_last_skipped, setKeepLastSkipped] = useState(true);
  const [keep_more_complete, setKeepMoreComplete] = useState(false);
  const [merge_tags, setMergeTags] = useState(true);
  const [skiptraceSoftware, setSkiptraceSoftware] = useState("");
  const campaign_name =
    localStorage.getItem("firstName")[0] + localStorage.getItem("lastName")[0];
  const [campaign_requirements, setCampaignRequirments] = useState("");
  const [campaing_market, setCampaignMarket] = useState("");
  const [campaignDone, setCampaignDone] = useState(false);
  const [updateCamp, setUpdateCamp] = useState(false);
  const [differentOwnersDone, setDifferentOwnersDone] = useState(false);
  const [SameOwnerDone, setSameOwnerDone] = useState(false);
  const [skipSoftwareDone, setSkipSoftwareDone] = useState(false);
  const [incomplteRecordsDone, setIncomplteRecordsDone] = useState(false);
  const [registration, setRegistration] = useState(false);
  const [clientTags, setClientTags] = useState(false);
  const [tagslifeDone, setTagslifeDone] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    if (location.state) {
      setEdit(true);
    }
  }, [location.state]);

  //add Campaign to database
  useEffect(() => {
    const uploadFormData = async () => {
      const formData = new FormData();
      formData.append("client_id", client_id);
      formData.append("campaign_name", campaign_name);
      formData.append("campaign_market", campaing_market);
      formData.append("campaign_requirements", campaign_requirements);
      const response = await createCampaign(formData);
      console.log(response);
    };
    if (campaignDone) {
      uploadFormData();
    }
  }, [updateCamp]);
  // update registration
  useEffect(() => {
    const updateClient = async () => {
      const formData = new FormData();
      formData.append("client_id", client_id);
      formData.append("skiptrace_software", skiptraceSoftware);
      formData.append("keep_last_skipped", keep_last_skipped);
      formData.append("merge_tags", merge_tags);
      formData.append("keep_more_complete", keep_more_complete);
      const response = await updateClientDetails(formData);
      if (response.success) {
        console.log("registration done", response.data);
      } else {
        console.error("Upload failed:", response.data);
      }
    };
    if (registration) {
      updateClient();
    }
  }, [registration]);
  //update client tags
  useEffect(() => {
    const updateClientTags = async () => {
      const payload = {
        client_id: client_id,
        clientTags: tags,
      };
      const response = await addClientTags(payload);
      if (response.success) {
        console.log("tag added successfully", response);
      } else {
        console.error("Upload failed:", response);
      }
    };

    if (clientTags) {
      updateClientTags();
    }
  }, [clientTags]);

  //handle Next Click
  const handleNextClick = (state, ref) => {
    if (state) {
      ref.current.nextCallback();
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: " Please Enter Required Data To Proceed",
        life: 3000,
      });
    }
  };

  return (
    <div>
      <Header
        username={localStorage.getItem("firstName")}
        profileLink="/options"
        profileImage={profile}
        setup={true}
        title={!edit ? "Ready for the setup ?" : "Edit your preferences"}
      />
      <Stepper
        ref={stepperRef}
        style={{ flexBasis: "50rem" }}
        linear
        className="mt-3"
      >
        {!edit && (
          <StepperPanel header="Campaign Details">
            <div>
              <WalkThroughCard
                description={true}
                activeIdx={0}
                totalIdx={totalIdx}
                question={
                  <span>
                    You will be created a special campaign that fits all your
                    needs, please enter any details you wish us to satisfy.
                  </span>
                }
                inputs={[
                  {
                    name: "",
                    placeholder: "e.g. 4 seats",
                    type: "Text",
                    key: "alphanum",
                  },
                  {
                    name: "Preferred Market:",
                    placeholder: "e.g. Columbus, Ohio",
                    type: "Text",
                    key: "alpha",
                  },
                ]}
                functions={[setCampaignRequirments, setCampaignMarket]}
                onSet={setCampaignDone}
              />
              <Toast ref={toast} />
              <div className="flex pt-4 justify-content-end">
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => {
                    handleNextClick(campaignDone, stepperRef);
                    setUpdateCamp(true);
                  }}
                />
              </div>
            </div>
          </StepperPanel>
        )}

        <StepperPanel header="Different Owners">
          <div>
            <WalkThroughCard
              twoOptions={true}
              activeIdx={1}
              totalIdx={totalIdx}
              question={
                <span>
                  {" "}
                  If we find duplicate records for the same Property Address
                  with different owners, which one would you like to keep?
                </span>
              }
              firstOption={
                <span>
                  <span className="font-semibold">Keep</span> the owner that is{" "}
                  <span className="font-semibold">last skipped</span>
                </span>
              }
              secondOption={
                <span>
                  <span className="font-semibold">Keep</span> the owner present
                  in the <span className="font-semibold">last uploaded</span>{" "}
                  list
                </span>
              }
              onSetOption={setKeepLastSkipped}
              onSet={setDifferentOwnersDone}
            />

            <Toast ref={toast} />
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick(differentOwnersDone, stepperRef)}
              />
            </div>
          </div>
        </StepperPanel>
        <StepperPanel header="Same Owner">
          <div>
            <WalkThroughCard
              twoOptions={true}
              activeIdx={2}
              totalIdx={totalIdx}
              question={
                <span>
                  If we find duplicate records for the same Property Address
                  with same owners, which option do you prioritize?
                </span>
              }
              firstOption={
                <span>
                  <span className="font-semibold">Merge Tags</span> associated
                  with both records into one record
                </span>
              }
              secondOption={
                <span>
                  <span className="font-semibold">Keep Tags</span> of the most
                  recent record skipped
                </span>
              }
              onSetOption={setMergeTags}
              onSet={setSameOwnerDone}
            />
            <Toast ref={toast} />
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick(SameOwnerDone, stepperRef)}
              />
            </div>
          </div>
        </StepperPanel>
        <StepperPanel header="Incomplete Records">
          <div>
            <WalkThroughCard
              twoOptions={true}
              activeIdx={3}
              totalIdx={totalIdx}
              question={
                <span>
                  If we find different incomplete records for the same Property
                  Address, which option do you prioritize?
                </span>
              }
              firstOption={
                <span>
                  <span className="font-semibold">Keep </span> the record with{" "}
                  <span className="font-semibold">
                    more complete mailing information
                  </span>
                </span>
              }
              secondOption={
                <span>
                  <span className="font-semibold">Keep </span> the record that
                  is{" "}
                  <span className="font-semibold">most recently skipped </span>
                </span>
              }
              onSetOption={setKeepMoreComplete}
              onSet={setIncomplteRecordsDone}
            />

            <Toast ref={toast} />
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() =>
                  handleNextClick(incomplteRecordsDone, stepperRef)
                }
              />
            </div>
          </div>
        </StepperPanel>
        <StepperPanel header="Skiptracing Software">
          <div>
            <WalkThroughCard
              options={true}
              totalIdx={totalIdx}
              optionsNames={["Kind Skip", "Batch Skip", "Pop Stream"]}
              activeIdx={4}
              question={
                <span>
                  What’s your most preferred software for skiptracing?
                </span>
              }
              onSetOption={setSkiptraceSoftware}
              onSet={setSkipSoftwareDone}
            />

            <Toast ref={toast} />
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick(skipSoftwareDone, stepperRef);
                  setRegistration(true);
                }}
              />
            </div>
          </div>
        </StepperPanel>
        <StepperPanel header="Tags">
          <div>
            <WalkThroughCard
              dropDown={true}
              activeIdx={5}
              totalIdx={totalIdx}
              question={
                <span>
                  Sometimes a tag is no longer valid after a certain amount of
                  time, choose the tags you don’t wish to keep forever and
                  decide their lifetime?
                </span>
              }
              onSetOption={setTags}
              onSet={setTagslifeDone}
            />

            <Toast ref={toast} />

            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick(tagslifeDone, stepperRef);
                  setClientTags(true);
                }}
              />
            </div>
          </div>
        </StepperPanel>
        <StepperPanel header="Final Step">
          <div>
            <WalkThroughCard
              final={true}
              activeIdx={6}
              totalIdx={totalIdx}
              question={
                <span>
                  Congratutions! You’re all set up. Ready to upload a list and
                  save money?
                </span>
              }
              destination={"/options"}
              uploadSuccessful={true}
              finalButton={"Start"}
              img={final}
            />

            <Toast ref={toast} />

            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  navigate("/options");
                }}
              />
            </div>
          </div>
        </StepperPanel>
      </Stepper>
    </div>
  );
}
