import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { ProgressBar } from "primereact/progressbar";
import { fetchTags } from "./api";
import { useQuery } from "react-query";
import { AutoComplete } from "primereact/autocomplete";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import "./Custom.css";

export default function WalkthroughCard(props) {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [bgColors, setBgColors] = useState({
    0: "#8de0ff",
    1: "#8de0ff",
    2: "#8de0ff",
  });
  const [skiptraceSoftware, setSkiptraceSoftware] = useState("");
  const [items, setItems] = useState();
  const [value, setValue] = useState("");
  const [inputValues, setInputValues] = useState([]);
  const [menuValues, setMenuValues] = useState([]);
  const [expiryPeriod, setExpiryPeriod] = useState("");
  const [currentTag, setCurrentTag] = useState("");
  const [visible, setVisible] = useState(false);
  const [listTags, setListTags] = useState([]);
  // choices for Tags expiry
  const Days = [30, 60, 90, 120, 150, 180, 210, 240];
  //fetch all the Tags in the System
  const { data: globalTags } = useQuery("fetchTags", fetchTags, {
    staleTime: 100 * 60 * 10,
    cacheTime: 1000 * 60 * 30,
  });
  //search for autocomplete tags
  const search = (event) => {
    let _filteredTags;

    if (!event.query.trim().length) {
      _filteredTags = [...globalTags];
    } else {
      _filteredTags = globalTags.filter((globalTag) => {
        return globalTag.name
          .toLowerCase()
          .startsWith(event.query.toLowerCase());
      });
    }

    setItems(_filteredTags);
  };
  //handle last button on the last step
  const handleClick = () => {
    if (props.confirm) {
      inputValues.forEach((val, index) => {
        if (val === "" || val === null) {
          props.emptyValueIndices.push(index);
        }
      });
      if (props.confirm()) {
        navigate(props.destination);
      }
    } else {
      navigate(props.destination);
    }
  };
  //handle expiry period for Setup
  const handleExpiry = (value) => {
    setExpiryPeriod(value);
  };
  //handle selection of tag to prompt user to add expiry period
  const handleTagSelect = (e) => {
    setCurrentTag(e.value);
    setVisible(true);
  };
  //handle confirm Button for tags
  const handleConfirm = () => {
    if (expiryPeriod === "") {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please enter an expiry period",
        life: 3000,
      });
      return;
    }
    handleTags(expiryPeriod, currentTag.tag_id, currentTag.name);
    setVisible(false);
    setExpiryPeriod("");
    setValue("");
  };
  //handle tag and their expiry
  const handleTags = (expiryPeriod, tagId, tag_name) => {
    setListTags((prevTags) => {
      const tagIndex = prevTags.findIndex((tag) => tag.tag_id === tagId);
      console.log(prevTags);

      if (tagIndex !== -1) {
        if (prevTags[tagIndex].expiry === expiryPeriod) {
          return prevTags;
        } else {
          const updatedTags = [...prevTags];
          updatedTags[tagIndex] = {
            ...updatedTags[tagIndex],
            expiration_period: expiryPeriod,
          };
          return updatedTags;
        }
      }

      const newTag = {
        tag_id: tagId,
        name: tag_name,
        expiration_period: expiryPeriod,
      };
      return [...prevTags, newTag];
    });
  };

  const removeTag = (index) => {
    setListTags((prevSelections) => {
      const newSelections = { ...prevSelections };
      delete newSelections[listTags[index].name];
      return newSelections;
    });
    const filteredTags = listTags.filter((_, i) => i !== index);

    setListTags(filteredTags);
    sessionStorage.setItem("listTags", JSON.stringify(filteredTags));
  };
  useEffect(() => {
    if (props.onSetOption) {
      const newTagsWithoutName = listTags.map((tag) => {
        const { name, ...rest } = tag;
        return rest;
      });
      props.onSetOption(newTagsWithoutName);
      props.onSet(true);
    }
  }, [listTags]);

  //handle selection from 2 options in SetUp
  const handleOption = (state, index) => {
    setBgColors((prevColors) => {
      const newColors = { ...prevColors };
      const otherIndex = index === 0 ? 1 : 0;
      newColors[index] = "#009CCF";
      newColors[otherIndex] = "#8de0ff";
      return newColors;
    });
    props.onSetOption(state);
    props.onSet(true);
  };
  //use effect to set all inputs and menu values to empty strings
  useEffect(() => {
    if (props.inputs) {
      setInputValues(props.inputs.map(() => ""));
    }
    if (props.menus) {
      setMenuValues(props.menus.map(() => ""));
    }
  }, []);
  //handle input Change
  const handleInputChange = (index, value) => {
    props.functions[index](value);
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
    const allValuesNotEmpty = newInputValues.every((val) => val !== "");
    if (allValuesNotEmpty) {
      props.onSet(true);
    } else {
      props.onSet(false);
    }
  };
  const handleDropMenu = (name, index, value) => {
    if (name === "Clients's name") {
      props.onChoice(true);
      const newInputValues = menuValues.map((item, i) => {
        if (i === index) {
          return value;
        } else {
          return "";
        }
      });
      setMenuValues(newInputValues);
    }
    props.functions[index](value);
    const newInputValues = [...menuValues];
    newInputValues[index] = value;
    setMenuValues(newInputValues);
    const allValuesNotEmpty = newInputValues.every((val) => val !== "");
    if (allValuesNotEmpty) {
      props.onSet(true);
    }
  };
  //handle change in preffered skip stoftware
  const handleSkipSoftware = (source, arg) => {
    if (source === "input") {
      setSkiptraceSoftware(arg.target.value);
      props.onSetOption(arg.target.value);
      setBgColors((prevColors) => {
        const newColors = { ...prevColors };
        newColors[arg] = "#009CCF";
        Object.keys(newColors).forEach((i) => {
          newColors[i] = "#8de0ff";
        });
        return newColors;
      });
      if (arg.target.value !== "") {
        props.onSet(true);
      } else {
        props.onSet(false);
      }
      console.log(arg.target.value);
    } else {
      props.onSetOption(props.optionsNames[arg]);
      props.onSet(true);
      setBgColors((prevColors) => {
        const newColors = { ...prevColors };
        newColors[arg] = "#009CCF";
        Object.keys(newColors).forEach((i) => {
          if (parseInt(i) !== arg) {
            newColors[i] = "#8de0ff";
          }
        });
        return newColors;
      });
    }
  };
  return (
    <div className="flex flex-col rounded-3xl mt-[10px] items-center justify-center w-[360px] mx-auto p-3 bg-gray-200 shadow-sm shadow-black min-w-min">
      <h2 className="text-black">{props.question}</h2>
      {props.twoOptions && (
        <div className="flex justify-content-center space-x-2 m-2 p-2">
          <Button
            className={`flex-grow w-full text-center rounded-2xl border-none text-[15px] text-black hover:bg-[#009ccf] bg-[#8de0ff] hover:text-white p-3`}
            style={{
              overflowWrap: "break-word",
              whiteSpace: "normal",
              minWidth: "55%",
              maxWidth: "55%",
              overflow: "hidden",
              backgroundColor: bgColors[0],
            }}
            onClick={() => {
              handleOption(true, 0);
            }}
          >
            {props.firstOption}
          </Button>
          <Button
            className={`flex-grow w-full text-center rounded-2xl border-none text-[15px] text-black hover:bg-[#009ccf] bg-[#8de0ff] hover:text-white p-3`}
            style={{
              overflowWrap: "break-word",
              whiteSpace: "normal",
              minWidth: "55%",
              maxWidth: "55%",
              overflow: "hidden",
              backgroundColor: bgColors[1],
            }}
            onClick={() => {
              handleOption(false, 1);
            }}
          >
            {props.secondOption}
          </Button>
        </div>
      )}
      {props.description && (
        <div className="w-full">
          {props.inputs.map((input, index) => (
            <div className="mt-2 mb-2" key={index}>
              <h2 className="text-black mb-1">{input.name}</h2>
              {input.type === "Text" ? (
                <InputTextarea
                  autoResize
                  keyfilter={
                    input.key === "alpha"
                      ? /^[A-Za-z ]+$/
                      : /^[A-Za-z0-9 _ ,-]+$/
                  }
                  value={inputValues[index]}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  rows={1}
                  className="w-full  p-2 border rounded text-black text-[15px]  font-sans"
                  placeholder={input.placeholder}
                />
              ) : (
                <InputNumber
                  inputId="minmax-buttons"
                  value={inputValues[index]}
                  onValueChange={(e) => handleInputChange(index, e.value)}
                  min={1}
                  showButtons
                  mode="decimal"
                  className="w-full  h-[40px] border rounded text-black text-[15px] text-center  font-sans"
                  placeholder={input.placeholder}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {props.options && (
        <div
          className={`flex flex-col  justify-center items-center w-full gap-3 p-[10px] text-center`}
        >
          <Button
            className="flex justify-center items-center w-full text-center rounded-2xl border-none text-black font-medium p-2 bg-[#8de0ff] hover:bg-[#009ccf] hover:text-white"
            onClick={() => {
              handleSkipSoftware("button", 0);
            }}
            style={{
              backgroundColor: bgColors[0],
            }}
          >
            {props.optionsNames[0]}
          </Button>
          <Button
            className="flex justify-center items-center w-full text-center rounded-2xl border-none text-black font-medium p-2 bg-[#8de0ff] hover:bg-[#009ccf] hover:text-white"
            onClick={() => {
              handleSkipSoftware("button", 1);
            }}
            style={{
              backgroundColor: bgColors[1],
            }}
          >
            {props.optionsNames[1]}
          </Button>
          <Button
            className="flex justify-center items-center w-full text-center rounded-2xl border-none text-black font-medium p-2 bg-[#8de0ff] hover:bg-[#009ccf] hover:text-white customInput "
            onClick={() => {
              handleSkipSoftware("button", 2);
            }}
            style={{
              backgroundColor: bgColors[2],
            }}
          >
            {props.optionsNames[2]}
          </Button>
          {props.next ? (
            <Button
              className="flex  h-[40px] justify-center items-center w-1/2 font-medium text-center rounded-2xl border-none text-black bg-[#00C4CC] hover:text-white"
              onClick={props.onClick}
            >
              {props.buttonText}
            </Button>
          ) : (
            <InputText
              type="text"
              className="flex  h-[40px] justify-center items-center w-full text-center rounded-2xl border-none text-black bg-[#00C4CC] customInput "
              placeholder="Enter Manually"
              value={skiptraceSoftware}
              onChange={(e) => handleSkipSoftware("input", e)}
            />
          )}
        </div>
      )}
      {props.dropDown && (
        <div className="w-full m-2 flex flex-col justify-center items-center">
          <div className="card w-full flex flex-col justify-content-center px-2 mx-[20px]">
            {listTags.map((tag, index) => (
              <div
                key={index}
                className=" flex flex-col object-contain bg-white text-black text-center rounded-lg px-1 py-1 text-sm mb-2 flex items-center justify-between w-full  relative"
              >
                <button
                  aria-label="Close"
                  className="absolute bg-[#8de0ff] top-[-18px] right-[-10px] mt-1 mr-1 ml-1 text-gray-500 w-[20px] h-[20px] rounded-full hover:text-gray-700"
                  onClick={() => removeTag(index)}
                >
                  <i
                    className="pi pi-times"
                    style={{ fontSize: "0.75rem" }}
                  ></i>
                </button>
                <div className="w-full flex h-full text-center break-words text-black items-center relative">
                  <div className="flex justify-start w-[100px] ">
                    {tag.name}
                  </div>
                  <span className="absolute left-1/2 transform -translate-x-1/2 font-bold">
                    |
                  </span>
                  <div className="flex justify-end items-center ml-auto  space-x-2 pl-4 flex-grow break-words ">
                    <span className="font-bold text-[#D3D3D3] ml-1">
                      {" "}
                      valid for
                    </span>
                    <span className="font-bold text-[#10c7c2] ml-1">
                      {tag.expiration_period}
                    </span>
                    <span className="ml-1">Days</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="card w-full flex justify-content-center px-2 mx-[20px]">
            <AutoComplete
              field="name"
              value={value}
              suggestions={items}
              completeMethod={search}
              placeholder="Enter Tag Here"
              onChange={(e) => setValue(e.value)}
              onSelect={handleTagSelect}
              className="bg-[#8de0ff] justify-center rounded-2xl  text-[15px] text-center w-full h-[40px]"
              dropdown
            />
          </div>
          <Toast ref={toast} />
          <Dialog
            visible={visible}
            modal
            className="flex flex-column justify-centerflex flex-col rounded-3xl  items-center justify-center w-[25%] p-3 bg-white shadow-sm shadow-black "
            onHide={() => {
              if (!visible) return;
              setVisible(false);
              setExpiryPeriod("");
              setValue("");
            }}
            content={({ hide }) => (
              <div>
                <div className="w-full mx-auto flex justify-center">
                  <p className="text-center p-2">
                    {" "}
                    Please choose the number of valid days for the tag{" "}
                    <span className="font-medium">{currentTag.name}</span>{" "}
                  </p>
                </div>
                <div>
                  <div className="flex justify-end items-center ml-auto  space-x-2 pl-4 flex-grow break-words p-3">
                    <Dropdown
                      value={expiryPeriod}
                      onChange={(e) => handleExpiry(e.value)}
                      options={Days}
                      placeholder="Enter Expiry Period"
                      className=" bg-gray-200 w-full rounded-2xl border-none text-[20px] font-semibold text-center text-black font-light hover:bg-blue-100 hover:text-black"
                    />
                    <span className="ml-1 px-2">Days</span>
                  </div>
                </div>
                <div className="flex align-items-center gap-2">
                  <Button
                    label="Confirm"
                    onClick={handleConfirm}
                    text
                    className="p-3 w-full text-[#009CCF] border-1 border-white-alpha-30 hover:text-[#8de0ff]"
                  ></Button>
                  <Button
                    label="Cancel"
                    onClick={hide}
                    text
                    className="p-3 w-full text-[#009CCF] border-1 border-white-alpha-30 hover:text-[#8de0ff]"
                  ></Button>
                </div>
              </div>
            )}
          ></Dialog>
        </div>
      )}
      {props.dropDowns && (
        <div className="w-full m-2 mb-4">
          {props.menus.map((menu, index) => (
            <div key={index}>
              <h2 className="text-black mt-2 mb-2">{menu.name}:</h2>
              <Dropdown
                filter
                disabled={props.disable[index]}
                loading={props.fetching[index]}
                value={menuValues[index]}
                onChange={(e) => handleDropMenu(menu.name, index, e.value)}
                options={props.drop_options[index]}
                optionLabel={menu.option}
                className="w-full"
                placeholder={menu.placeholder}
              />
            </div>
          ))}
        </div>
      )}
      {props.ProgressBar && !props.uploadSuccessful && (
        <>
          <div className="mb-4"></div>
          <ProgressBar className="w-full" value={props.progress} />
        </>
      )}
      {props.final && (
        <div className="flex flex-col items-center text-center justify-center space-y-4">
          {props.uploadSuccessful && (
            <>
              {props.img && (
                <img src={props.img} alt="Trophy" className="w-2/4 m-3" />
              )}

              <Button
                className="rounded-full bg-[#8de0ff] text-black mt-2 flex text-[15px] justify-center items-center h-full font-semibold p-2 w-[200px] hover:bg-[#009ccf] hover:text-white"
                onClick={() => {
                  handleClick();
                }}
              >
                {props.finalButton}
              </Button>
            </>
          )}
        </div>
      )}
      {!props.alone && (
        <div className="w-full bg-gray-300 rounded-2xl m-[20px]">
          <div
            className="bg-[#009ccf] h-6 rounded-2xl"
            style={{
              width: `${((props.activeIdx + 1) / props.totalIdx) * 100}%`,
            }}
          >
            <span className="text-white text-center w-full block"></span>
          </div>
        </div>
      )}
    </div>
  );
}
