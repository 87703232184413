import React, { useState, useEffect, useRef } from "react";

export default function Carousel({ slides }) {
  const [current, setCurrent] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const length = slides.length;

  const totalSlides = length + 2;
  const timeoutRef = useRef(null);

  const nextSlide = () => {
    if (!isTransitioning) return;
    setIsTransitioning(true);
    setCurrent((prev) => prev + 1);
  };

  const prevSlide = () => {
    if (!isTransitioning) return;
    setIsTransitioning(true);
    setCurrent((prev) => prev - 1);
  };

  // Auto slide every 10 seconds
  useEffect(() => {
    const interval = setInterval(nextSlide, 10000);
    return () => clearInterval(interval);
  }, []);

  // Handle the infinite loop effect
  useEffect(() => {
    if (current === totalSlides - 1) {
      timeoutRef.current = setTimeout(() => {
        setIsTransitioning(false);
        setCurrent(1);
      }, 700);
    }

    if (current === 0) {
      timeoutRef.current = setTimeout(() => {
        setIsTransitioning(false);
        setCurrent(length);
      }, 700);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [current, totalSlides, length]);

  useEffect(() => {
    if (!isTransitioning) {
      const timeout = setTimeout(() => {
        setIsTransitioning(true);
      }, 50);

      return () => clearTimeout(timeout); // Cleanup on unmount
    }
  }, [isTransitioning]);

  return (
    <div className="relative flex justify-center items-center w-full">
      <div className="relative w-full overflow-hidden">
        <button
          className="right-arrow absolute top-1/2 right-5 transform -translate-y-1/2 bg-transparent text-2xl text-gray-700 hover:scale-110 transition-transform z-10"
          onClick={nextSlide}
        >
          <i className="pi pi-angle-right"></i>
        </button>

        <div
          className={`carousel-content flex ${
            isTransitioning
              ? "transition-transform duration-700 ease-in-out"
              : ""
          }`}
          style={{
            transform: `translateX(-${current * 100}%)`,
            height: "600px",
          }}
        >
          <div
            className="carousel-item w-full flex-shrink-0 p-6"
            style={{ minWidth: "100%" }}
          >
            <div className="flex justify-center items-center h-full">
              {slides[length - 1].content}
            </div>
          </div>

          {slides.map((slide, index) => (
            <div
              key={index}
              className="carousel-item w-full flex-shrink-0 p-6"
              style={{ minWidth: "100%" }}
            >
              <div className="flex justify-center items-center h-full">
                {slide.content}
              </div>
            </div>
          ))}

          <div
            className="carousel-item w-full flex-shrink-0 p-6"
            style={{ minWidth: "100%" }}
          >
            <div className="flex justify-center items-center h-full">
              {slides[0].content}
            </div>
          </div>
        </div>

        <button
          className="left-arrow absolute top-1/2 left-5 transform -translate-y-1/2 bg-transparent text-2xl text-gray-700 hover:scale-110 transition-transform z-10"
          onClick={prevSlide}
        >
          <i className="pi pi-angle-left"></i>
        </button>
      </div>
    </div>
  );
}
