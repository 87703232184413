import React, { useState, useRef, useEffect, useCallback } from "react";
import Header from "./Header";
import Card from "./OptionCard";
import WalkThroughCard from "./WalkthroughCard";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";
import Mapping from "./Mapping";
import { fetchClients, uploadSikppedList, fetchSkipFiles } from "./api";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import success from "../imgs/success-cropped.svg";
import final from "../imgs/SkiptraceFinal-cropped.svg";
import profile from "../imgs/profile-cropped.svg";

export default function SkipUpload() {
  const stepperRef = useRef(null);
  const warning = useRef(null);
  const navigate = useNavigate();
  const [uploaded_file, setUploadedFile] = useState(null);
  const [readyForUpload, setReadyForUpload] = useState(false);
  const [upload_date, setUploadDate] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [titleUpload, setTitleUpload] = useState(
    "Upload any list in csv or xlsx format"
  );
  const [mappings, setMappings] = useState({});
  const [approved, setApproved] = useState(false);
  const [getCol, setGetCol] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [client, setClient] = useState("");
  const [clientFiles, setClientFiles] = useState([]);
  const [dropDisable, setDropDisable] = useState(true);
  const [filesLoading, setFilesLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileDetails, setFileDetails] = useState(false);
  const [skip_date, setSkipDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  //handle file upload
  useEffect(() => {
    async function retrieveClientFiles(client) {
      try {
        const files = await fetchSkipFiles(client);
        console.log(files);
        setClientFiles(files);
        setFilesLoading(false);
        setDropDisable(false);
      } catch (error) {
        console.error("Error fetching client files:", error);
      }
    }
    if (client) {
      retrieveClientFiles(client);
    }
  }, [client]);

  const handleFileUpload = (file) => {
    // TODO: adjust file types + excel files because they cause an error
    const validTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!validTypes.includes(file.type)) {
      warning.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "File type not supported. Please upload a CSV or Excel file.",
        life: 3000,
      });
      return;
    }
    setUploadedFile(file);
    console.log(file);

    setUploadDate(new Date().toISOString().split("T")[0]);
    setTitleUpload("Your file is uploaded successfully");
    setShowSuccessMessage(true);
  };
  //handle close button of success message
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  //get clients
  const { data: clients, isLoading: clientsLoading } = useQuery(
    "fetchClients",
    fetchClients,
    {
      onSuccess: () => {
        console.log("Clients are here");
      },
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
    }
  );
  //Warning in case clicking next without entering required data
  const showWarn = () => {
    warning.current.show({
      severity: "warn",
      summary: "Warning",
      detail: " Please Enter Required Data To Proceed",
      life: 3000,
    });
  };
  //notification when upload is successful
  const notify = () => {
    toast.success(
      "The file has been successfully processed. You can now view the details in the database",
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };
  const showErorr = () => {
    toast.error(
      "There was an issue uploading the file. Please try again later. If the problem continues, contact the engineering team for assistance.",
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };
  // Handle clicking on next
  const handleNextClick = (current_step, state, ref) => {
    console.log(current_step);
    if (state) {
      ref.current.nextCallback();
    } else {
      if (current_step === "mapping") {
        warning.current.show({
          severity: "warn",
          summary: "Warning",
          detail: " Please Confirm The Mapping To Proceed ",
          life: 3000,
        });
      } else {
        showWarn();
      }
    }
  };
  //handle calendar input
  const handleCalendarInput = (card, choice) => {
    if (card === "Skip Date") {
      setSkipDate(choice);
    }
  };
  const uploadFileToClient = useCallback(async () => {
    const formData = new FormData();
    formData.append("client_id", client.client_id);
    formData.append("upload_date", upload_date);
    formData.append("uploaded_file", uploaded_file);
    formData.append("file_id", fileName.file_id);
    formData.append("skip_date", skip_date);
    formData.append("mappings", JSON.stringify(mappings));
    let _val = progress;

    console.log("uploading");
    const response = await uploadSikppedList(
      formData,
      _val,
      setProgress,
      setUploadSuccessful
    );
    if (response.success) {
      notify();
    } else {
      showErorr();
      navigate("/data-options");
    }
  }, [
    client.client_id,
    upload_date,
    uploaded_file,
    fileName.file_id,
    JSON.stringify(mappings),
    skip_date,
  ]);
  //handle file upload to send the file to the client
  useEffect(() => {
    if (readyForUpload) {
      uploadFileToClient();
    }
  }, [readyForUpload]);

  return (
    <div>
      <Header
        username={sessionStorage.getItem("firstName")}
        profileLink="/data-options"
        upload={true}
        profileImage={profile}
      />
      {showSuccessMessage && (
        <Dialog
          header="Success"
          visible={showSuccessMessage}
          onHide={closeSuccessMessage}
        >
          <div className="flex flex-col justify-center">
            <div className="alert alert-success">
              File {uploaded_file.name} uploaded successfully!
            </div>
          </div>
        </Dialog>
      )}
      <div className="flex flex-col min-h-svh gap-12">
        <Stepper ref={stepperRef} style={{ flexBasis: "50rem" }} linear>
          <StepperPanel header="File Details">
            <div>
              <WalkThroughCard
                dropDowns={true}
                menus={[
                  {
                    name: "Clients's name",
                    placeholder: "Enter Client Name",
                    option: "full_name",
                  },
                  {
                    name: "File name",
                    placeholder: "Enter File Name",
                    option: "file_name",
                  },
                ]}
                drop_options={[clients, clientFiles]}
                disable={[false, dropDisable]}
                onSet={setFileDetails}
                functions={[setClient, setFileName]}
                fetching={[clientsLoading, filesLoading]}
                onChoice={setFilesLoading}
                activeIdx={0}
                totalIdx={5}
                question={<span></span>}
              />

              <Toast ref={warning} />
              <div className="flex pt-4 justify-content-end">
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => {
                    handleNextClick("file details", fileDetails, stepperRef);
                  }}
                />
              </div>
            </div>
          </StepperPanel>
          <StepperPanel header="Upload File">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={1}
                  totalIdx={5}
                  title={titleUpload}
                  upload={true}
                  image={success}
                  uploadedFile={uploaded_file}
                  onFileUpload={handleFileUpload}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setClient("");
                  setFileName("");
                  setFileDetails(false);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("file", uploaded_file, stepperRef);

                  setGetCol(true);
                  setApproved(false);
                }}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Mapping ">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Mapping
                  file={uploaded_file}
                  onMapped={setApproved}
                  approved={approved}
                  fetch={getCol}
                  setFetch={setGetCol}
                  stepperRef={stepperRef}
                  mapping={setMappings}
                  client={client.client_id}
                  skiptracing={true}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick("mapping", approved, stepperRef)}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Skip Date">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={3}
                  totalIdx={5}
                  skipDate={true}
                  title={"When did you last skiptrace that list?"}
                  calendar={true}
                  onCalendarInput={handleCalendarInput}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                disabled
                onClick={() => {
                  stepperRef.current.prevCallback();
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("date", skip_date, stepperRef);
                  setReadyForUpload(true);
                }}
              />
            </div>
          </StepperPanel>

          <StepperPanel header="Final Step">
            <div>
              <WalkThroughCard
                final={true}
                activeIdx={4}
                totalIdx={5}
                question={
                  <span>
                    {uploadSuccessful
                      ? "Congratulations! The file list successfully uploaded. We are currently breaking down the file into records and will notify you once the process is complete.Would you like to upload another list?"
                      : "Please wait the file is uploading"}
                  </span>
                }
                ProgressBar={true}
                progress={progress}
                uploadSuccessful={uploadSuccessful}
                destination={"/data-options"}
                finalButton={"Upload Another List"}
                img={final}
              />

              <Toast ref={warning} />

              <div className="flex pt-4 justify-content-end">
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => {
                    navigate("/data-options");
                  }}
                />
              </div>
            </div>
          </StepperPanel>
        </Stepper>
      </div>
    </div>
  );
}
