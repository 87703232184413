import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import profile from "../imgs/profile-cropped.svg";
import { useQuery } from "react-query";
import { DataView } from "primereact/dataview";
import { useAuth } from "./AuthContext";
import {
  fetchCampaign,
  fetchClientTags,
  fetchFileTags,
  fileDetails,
} from "./api";
import "./Custom.css";
export default function File() {
  const location = useLocation();
  const refRecords = useRef(null);
  const refSources = useRef(null);
  const refTags = useRef(null);
  const refOther = useRef(null);
  const { state } = location;
  const fileID = state?.fileID;
  const [data, setData] = useState({});
  const [retrieved, setRetrieved] = useState(false);
  const { role } = useAuth();
  const [fetch, setFetch] = useState(true);
  let sourcesAndDates = [];
  let campAndTags = [];
  let detailsArray = [];
  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();
      formData.append(
        "client_id",
        "client_id" in state
          ? state.client_id
          : localStorage.getItem("client_id")
      );
      formData.append("file_id", fileID);

      const response = await fileDetails(formData);
      console.log(response);
      setData(response);
      setRetrieved(true);
    };
    fetchData();
  }, [fileID]);

  //get campaign name
  const {
    data: campaigns,
    isLoading: campLoading,
    isFetching: campFetching,
  } = useQuery(
    [
      "fetchCampaign",
      "client_id" in state
        ? state.client_id
        : localStorage.getItem("client_id"),
    ],
    () =>
      fetchCampaign(
        "client_id" in state
          ? state.client_id
          : localStorage.getItem("client_id")
      ),
    {
      onSuccess: () => {
        console.log("camapaings are here");
      },
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 30,
    }
  );
  //get file tags
  const {
    data: tags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useQuery(
    ["fetchFileTags", fileID],
    () => fetchFileTags(fileID),

    {
      enabled: fetch,
      onSuccess: () => {
        setFetch(false);
      },
    }
  );
  //get client tags
  const {
    data: clientTags,
    isLoading: clientTagsLoading,
    isFetching: clientTagsFetching,
  } = useQuery(
    [
      "fetchClientTags",
      "client_id" in state
        ? state.client_id
        : localStorage.getItem("client_id"),
    ],
    () =>
      fetchClientTags(
        "client_id" in state
          ? state.client_id
          : localStorage.getItem("client_id")
      ),
    {
      onSuccess: () => {
        console.log("client tags");
      },
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 5,
    }
  );

  const itemTemplate = (entry, index) => {
    return (
      <div className="col-12" key={entry.id}>
        <div className="flex flex-column xl:flex-row xl:align-items-center p-4 gap-4 border-top-1 surface-border">
          <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-center flex-1 gap-4">
            <div className="flex flex-column align-items-center justify-content-center sm:align-items-start gap-3">
              <div className="font-bold text-900 bg-[#D3D3D3] rounded-full p-2 text-center text-[15px] w-full">
                {entry.name}
              </div>
              <div className="flex align-items-center gap-3">
                {entry.description && typeof entry.description === "object" ? (
                  <div>
                    {entry.description.tags.map((tag, index) => (
                      <div
                        key={index}
                        className="flex flex-col object-contain bg-white text-black text-center rounded-lg px-1 py-1 text-sm mb-2 flex items-center justify-between w-full relative"
                      >
                        <div className="w-full flex h-full text-center break-words text-black items-center relative">
                          <div className="flex justify-center   bg-[#8de0ff] rounded-md font-semibold p-2 hovering cursor-pointer">
                            {tag}
                          </div>
                          <div className="hide absolute text-[12px] top-[-25px] left-[-70px] justify-center text-center break-words  bg-[black] opacity-50 rounded-md font-semibold p-2 ">
                            valid for {entry.description.expiration[index]} days
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span className="flex align-items-center gap-2 p-3">
                    <span className="font-semibold">{entry.description}</span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const listTemplate = (items) => {
    if (!items || items.length === 0) return null;

    let list = items.map((entry, index) => {
      return itemTemplate(entry, index);
    });

    return (
      <div
        className={`grid lg:grid-cols-${items.length} md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1`}
      >
        {list}
      </div>
    );
  };
  if (
    !campLoading &&
    !tagsLoading &&
    !clientTagsLoading &&
    !tagsFetching &&
    !clientTagsFetching &&
    retrieved
  ) {
    let expiry = [];
    tags.data.forEach((tag) => {
      let valid = "";
      const value = clientTags.find((obj) => obj.tag_id === tag.tag_id);
      if (value) {
        valid = value.expiration_period;
      } else {
        valid = "365";
      }

      expiry = [...expiry, valid];
    });

    const sources = {
      "List Source": data.source,
      "Pull Date": data.list_pull_date,
      "Upload Date": data.upload_date,
    };
    const campTag = {
      "Campaign Name": campaigns[0].campaign_name,
      Tags: { tags: tags.tag_names, expiration: expiry },
    };
    const other = {
      Name: localStorage.getItem("firstName"),
      Department: data.cold_calling ? "CC" : "Text",
      Version: `v${data.current_version}`,
      Extension: `.${data.file_type}`,
    };
    sourcesAndDates = Object.entries(sources).map(([key, value]) => {
      return {
        name: key,
        description: value,
      };
    });
    campAndTags = Object.entries(campTag).map(([key, value]) => {
      return {
        name: key,
        description: value,
      };
    });
    detailsArray = Object.entries(other).map(([key, value]) => {
      return {
        name: key,
        description: value,
      };
    });
  }

  return (
    <div>
      <Header
        username={
          role === "user" ? localStorage.getItem("firstName") + "'s" : ""
        }
        profileLink="/options"
        profileImage={profile}
        file={true}
      />
      {!tagsLoading &&
      !clientTagsLoading &&
      !clientTagsFetching &&
      !tagsFetching ? (
        <div>
          {data.deduplicated && (
            <Panel
              ref={refRecords}
              header={
                <span className="text-[20px] text-center h-[60px]">
                  {" "}
                  Your file in numbers{" "}
                  <i
                    className="pi pi-calculator "
                    style={{ margin: "10px", fontSize: "1rem" }}
                  ></i>
                </span>
              }
              toggleable
              onClick={() => refRecords.current.toggle()}
              collapsed
            >
              <div className="mb-4 mt-2">
                <div className="flex justify-center grid gap-6">
                  <div className="flex flex-col items-center">
                    <div className="text-md font-semibold w-[260px]  overflow-wrap break-words text-center m-1">
                      Records in the uploaded document
                    </div>
                    <div className=" bg-[#8DE0FF] flex items-center justify-center shadow-custom font-bold text-900 rounded-full p-2 text-center text-[15px] w-1/2">
                      {data.total_records}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-md font-semibold w-[260px]  overflow-wrap break-words text-center m-1">
                      Records uploaded that have been present in the database
                    </div>
                    <div className=" bg-[#DD4242] flex items-center justify-center shadow-custom font-bold text-900 rounded-full p-2 text-center text-[15px] w-1/2">
                      {data.duplicates_in_system}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-md font-semibold w-[260px] overflow-wrap break-words text-center m-1">
                      New records added according to the preset preferences
                    </div>
                    <div className="bg-[#00C4CC] flex items-center justify-center shadow-custom font-bold text-900 rounded-full p-2 text-center text-[15px] w-1/2">
                      {data.total_records -
                        data.duplicates_in_system -
                        data.duplicates_removed}
                    </div>
                  </div>
                  {data.skiptraced_by_res && (
                    <div className="flex flex-col items-center">
                      <div className="text-md font-semibold w-[260px] overflow-wrap break-words text-center m-1">
                        Money saved by using our deduplication service
                      </div>
                      <div className="bg-[#F69868] flex items-center justify-center shadow-custom font-bold text-900 rounded-full p-2 text-center text-[15px] w-1/2">
                        {data.savings}$
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Panel>
          )}

          <Panel
            ref={refSources}
            header={
              <span className="text-[20px] text-center h-[60px]">
                {" "}
                Sources and Dates{" "}
                <i
                  className="pi pi-calendar"
                  style={{ margin: "10px", fontSize: "1rem" }}
                ></i>
              </span>
            }
            toggleable
            onClick={() => refSources.current.toggle()}
            collapsed
          >
            <div className="card">
              <DataView
                value={sourcesAndDates}
                listTemplate={listTemplate}
                className="mx-auto"
              />
            </div>
          </Panel>
          <Panel
            ref={refTags}
            header={
              <span className="text-[20px] text-center h-[60px]">
                Campaign and Tags{" "}
                <i
                  className="pi pi-tags"
                  style={{ margin: "10px", fontSize: "1rem" }}
                ></i>
              </span>
            }
            toggleable
            onClick={() => refTags.current.toggle()}
            collapsed
          >
            <div className="card">
              <DataView
                value={campAndTags}
                listTemplate={listTemplate}
                className="mx-auto"
              />
            </div>
          </Panel>
          <Panel
            ref={refOther}
            header={
              <span className="text-[20px] text-center h-[60px]">
                Other details{" "}
                <i
                  className="pi pi-server"
                  style={{ margin: "10px", fontSize: "1rem" }}
                ></i>
              </span>
            }
            toggleable
            onClick={() => refOther.current.toggle()}
            collapsed
          >
            <div className="card">
              <DataView
                value={detailsArray}
                listTemplate={listTemplate}
                className="mx-auto"
              />
            </div>
          </Panel>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center space-y-4">
          <h2 className="m-4">Please wait we're retrieving your data</h2>
          <ProgressSpinner
            style={{ width: "100px", height: "100px", margin: "12px" }}
          />
        </div>
      )}
    </div>
  );
}
