const joyrideStyles = {
  options: {
    arrowColor: "#009ccf", // The color of the arrow
    backgroundColor: "white", // Background of the tooltip
    overlayColor: "transparent", // Overlay color with opacity for better UX
    primaryColor: "#009ccf", // Primary color for buttons
    textColor: "#009ccf", // Text color inside the tooltip
    width: 400, // Set a consistent width for the tooltip
    zIndex: 1000, // Ensure Joyride is on top of other UI elements
  },
  spotlight: {
    backgroundColor: "transparent", // Make spotlight transparent
    boxShadow: "0 0 10px rgb(141 224 255 )", // Create an effect of spotlight around the element
    borderRadius: "20px", // Adjust as needed for rounded corners
  },
  tooltip: {
    borderRadius: "20px", // Rounded corners for tooltips
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // Slight shadow for depth
    fontFamily: "Open-Sauce ,sans-serif",
  },
  overlay: {
    backgroundColor: "transparent", // Make sure the overlay is transparent so only the shadow shows
  },
  buttonNext: {
    backgroundColor: "#009ccf", // Next button color
    color: "#ffffff", // Text color for buttons
  },
  buttonBack: {
    color: "#009ccf", // Back button color
  },
  buttonClose: {
    color: "#009ccf", // Close button color
  },
};

// Export the joyrideStyles object as a named export
export default joyrideStyles;
