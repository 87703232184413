import React, { useState } from "react";
import Header from "./Header";
import profile from "../imgs/profile-cropped.svg";
import Card from "./OptionCard";
import upload from "../imgs/upload-cropped.svg";
import database from "../imgs/database-cropped.svg";
import skiptrace from "../imgs/skiptrace-cropped.svg";
import request from "../imgs/request-cropped.svg";
import "./DoneIcon.css";
import joyrideStyles from "./JoyRideStyles";
import { Button } from "primereact/button";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
const steps = [
  {
    content: (
      <>
        <h2>Welcome to RES Data Services!</h2>
        <p>
          Join us for a quick tour to explore all the features and options we
          have to offer.
        </p>
      </>
    ),
    placement: "center",
    target: "body",
  },
  {
    content: (
      <>
        <p>
          Click here to view and update your profile details and access support
          seamlessly whenever you need it.
        </p>
      </>
    ),
    target: ".avatar",
    placement: "auto",
    offset: 150,
    disableOverlayClose: true, // Prevent closing on overlay click
    disableBeacon: true, // Skip beacon for direct highlighting
    spotlightClicks: true,
  },
  {
    target: ".card1",
    content:
      "Need a specific list pulled? Use the 'Pull a List for Me' feature to quickly request and retrieve the data you need, tailored to your exact specifications.",
  },
  {
    target: ".card2",
    content:
      "Got a new list? Upload it to the dialer with ease. Just input your file and its details, which will be sent to the dialer for immediate calling.",
  },
  {
    target: ".card3",
    content:
      "Get the best value with our Skiptrace service. Achieve higher accuracy at a lower cost by leveraging our system's data to efficiently trace your list.",
  },
  {
    target: ".card4",
    content:
      "Explore the Database! View and download all lists uploaded to the dialer, track key metrics, and keep essential notes—all in one place.",
  },
];
export default function OptionsScreen() {
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if (action === ACTIONS.CLOSE) {
      // do something
      setRun(false);
      setStepIndex(0);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      // Update state to advance the tour

      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // You need to set our running state to false, so we can restart if we click start again.
      setStepIndex(0);
      setRun(false);
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

  const handleTour = () => {
    setRun(true);
  };
  return (
    <div>
      <Joyride
        steps={steps}
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        continuous={true}
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={joyrideStyles}
      />
      <Header
        username={localStorage.getItem("firstName")}
        profileLink="/options"
        profileImage={profile}
        options={true}
        file={false}
        logoutVisible={true}
      />
      <div className="flex flex-row items-end justify-end p-3 ml-auto">
        <Button
          className="text-[#009ccf] text-md px-5 block text-right"
          onClick={handleTour}
        >
          Explore Our Services
          <i className="pi pi-play-circle ml-2"></i>
        </Button>
      </div>
      <div className=" flex flex-wrap gap-4 justify-center p-4">
        <Card
          image={request}
          title="Pull A List For Me"
          buttonText="Request"
          destination="/list-criteria"
          steps={false}
          className={"card1"}
        />
        <Card
          image={upload}
          title="Upload a New List to the Dialer"
          buttonText="Upload"
          destination={"/upload"}
          steps={false}
          className={"card2"}
        />
        <Card
          image={skiptrace}
          title="Skiptrace and Clean"
          buttonText="Start"
          destination="/skiptrace-service"
          steps={false}
          className={"card3"}
        />
        <Card
          image={database}
          title="Go to database"
          buttonText="Go"
          destination="/database"
          steps={false}
          className={"card4"}
        />
      </div>
    </div>
  );
}
