import React from 'react';
import image from '../imgs/404-cropped.svg';

export default function UnauthorizedScreen() {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <img src={image} alt="404 error" className="max-w-xs mt-10" />
            <h1 className="text-4xl font-light text-center mt-10">Sorry, you do not have access to this screen.</h1>
        </div>
    );
}