import React, { useState, useEffect, useRef, useCallback } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import Mapping from "./Mapping";
import { useNavigate } from "react-router-dom";
import { fetchClients } from "./api";
import { useQuery } from "react-query";
import {
  fetchTags,
  fetchCampaign,
  fetchBadFiles,
  fileDetails,
  uploadProcessedFile,
} from "./api";
import success from "../imgs/success-cropped.svg";
import profile from "../imgs/profile-cropped.svg";
import Card from "./OptionCard";
import Header from "./Header";
import WalkThroughCard from "./WalkthroughCard";
import final from "../imgs/SkiptraceFinal-cropped.svg";
import "./Multistep.css";
export default function ProcessedFileUpload() {
  const stepperRef = useRef(null);
  const warning = useRef(null);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [titleUpload, setTitleUpload] = useState(
    "Upload any list in csv or xlsx format"
  );

  const [uploaded_file, setUploadedFile] = useState(null);

  const [activeIdx, setActiveIdx] = useState(0);
  const [progress, setProgress] = useState(0);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [readyForUpload, setReadyForUpload] = useState(false);
  const [client, setClient] = useState("");
  const [clientFiles, setClientFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileInfo, setFileInfo] = useState(false);
  const [mappings, setMappings] = useState({});
  const [approved, setApproved] = useState(false);
  const [getCol, setGetCol] = useState(false);
  const [dropDisable, setDropDisable] = useState(true);
  //get client files
  useEffect(() => {
    async function retrieveClientFiles(client) {
      try {
        const files = await fetchBadFiles(client);
        console.log(files);
        setClientFiles(files);
        setFilesLoading(false);
        setDropDisable(false);
      } catch (error) {
        console.error("Error fetching client files:", error);
      }
    }
    if (client) {
      retrieveClientFiles(client);
    }
  }, [client]);
  //fetch File details that client already set during list upload

  //upload file
  const uploadFormData = useCallback(async () => {
    const formData = new FormData();
    formData.append("mappings", JSON.stringify(mappings));
    formData.append("file_id", fileName.file_id);
    formData.append("uploaded_file", uploaded_file);
    let _val = progress;
    setProgress(1);
    const response = await uploadProcessedFile(
      formData,
      _val,
      setProgress,
      setUploadSuccessful
    );
    if (response.success) {
      notify();
    } else {
      showErorr();
    }
  }, [mappings, uploaded_file]);
  useEffect(() => {
    if (readyForUpload) {
      console.log("here");
      uploadFormData();
    }
  }, [readyForUpload]);

  //get clients
  const {
    data: clients,
    isLoading: clientsLoading,
    isFetching: clientsFetching,
  } = useQuery("fetchClients", fetchClients, {
    onSuccess: () => {
      console.log("Clients are here");
    },
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30,
  });

  //Warning in case clicking next without entering required data
  const showWarn = () => {
    warning.current.show({
      severity: "warn",
      summary: "Warning",
      detail: " Please Enter Required Data To Proceed",
      life: 3000,
    });
  };
  //notification when upload is successful
  const notify = () => {
    toast.success(
      "The file has been successfully processed. You can now view the details in the database",
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };
  //notification when there is an error
  const showErorr = () => {
    toast.error(
      "There was an issue uploading the file. Please try again later. If the problem continues, contact the engineering team for assistance.",
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };
  // Handle clicking on next
  const handleNextClick = (current_step, state, ref) => {
    console.log(current_step);
    if (state) {
      ref.current.nextCallback();
      setActiveIdx((prev) => prev + 1);
    } else {
      if (current_step === "mapping") {
        warning.current.show({
          severity: "warn",
          summary: "Warning",
          detail: " Please Confirm The Mapping To Proceed ",
          life: 3000,
        });
      } else {
        showWarn();
      }
    }
  };
  console.log(mappings);
  //Handle Upload file
  const handleFileUpload = (file) => {
    // TODO: adjust file types + excel files because they cause an error
    const validTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!validTypes.includes(file.type)) {
      warning.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "File type not supported. Please upload a CSV or Excel file.",
        life: 3000,
      });
      return;
    }
    setUploadedFile(file);
    console.log(file);
    setTitleUpload("Your file is uploaded successfully");
    setShowSuccessMessage(true);
  };
  //handle close button of success message
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  return (
    <div>
      <Header
        username={""}
        profileLink="/options"
        profileImage={profile}
        upload={true}
      />
      {showSuccessMessage && (
        <Dialog
          header="Success"
          visible={showSuccessMessage}
          onHide={closeSuccessMessage}
        >
          <div className="flex flex-col justify-center">
            <div className="alert alert-success">
              File {uploaded_file.name} uploaded successfully!
            </div>
          </div>
        </Dialog>
      )}
      <div className="flex flex-col min-h-svh gap-12">
        <Stepper ref={stepperRef} linear style={{ flexBasis: "50rem" }}>
          <StepperPanel header="File Details">
            <div>
              <WalkThroughCard
                dropDowns={true}
                menus={[
                  {
                    name: "Clients's name",
                    placeholder: "Enter Client Name",
                    option: "full_name",
                  },
                  {
                    name: "File name",
                    placeholder: "Enter File Name",
                    option: "file_name",
                  },
                ]}
                drop_options={[clients, clientFiles]}
                disable={[false, dropDisable]}
                onSet={setFileInfo}
                functions={[setClient, setFileName]}
                fetching={[clientsLoading, filesLoading]}
                onChoice={setFilesLoading}
                activeIdx={activeIdx}
                totalIdx={3}
                question={<span></span>}
              />

              <Toast ref={warning} />
              <div className="flex pt-4 justify-content-end">
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => {
                    handleNextClick("client", fileInfo, stepperRef);
                  }}
                />
              </div>
            </div>
          </StepperPanel>
          <StepperPanel header="Upload File">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  totalIdx={3}
                  title={titleUpload}
                  upload={true}
                  image={success}
                  uploadedFile={uploaded_file}
                  onFileUpload={handleFileUpload}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                  setClient("");
                  setFileName("");
                  setFileInfo(false);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("file", uploaded_file, stepperRef);

                  setGetCol(true);
                  setApproved(false);
                }}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Mapping ">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Mapping
                  file={uploaded_file}
                  onMapped={setApproved}
                  approved={approved}
                  fetch={getCol}
                  setFetch={setGetCol}
                  stepperRef={stepperRef}
                  mapping={setMappings}
                  client={client.client_id}
                  ready={setReadyForUpload}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick("mapping", approved, stepperRef)}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Final Step">
            <div>
              <WalkThroughCard
                final={true}
                activeIdx={activeIdx}
                totalIdx={3}
                question={
                  <span>
                    {uploadSuccessful
                      ? "Congratulations! The file list successfully uploaded. We are currently breaking down the file into records and will notify you once the process is complete."
                      : "Please wait the file is uploading"}
                  </span>
                }
                destination={"/data-options"}
                ProgressBar={true}
                progress={progress}
                uploadSuccessful={uploadSuccessful}
                finalButton={"Upload Another List"}
                img={final}
              />

              <Toast ref={warning} />

              <div className="flex pt-4 justify-content-between">
                <Button
                  label="Back"
                  severity="secondary"
                  disabled
                  icon="pi pi-arrow-left"
                  onClick={() => {
                    stepperRef.current.prevCallback();
                    setActiveIdx((prev) => prev - 1);
                  }}
                />

                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => {
                    navigate("/data-options");
                  }}
                />
              </div>
            </div>
          </StepperPanel>
        </Stepper>
      </div>
    </div>
  );
}
