import React, { useState, useEffect, useRef } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import Mapping from "./Mapping";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import {
  fetchClients,
  fetchClientTags,
  fetchCampaign,
  fetchTags,
  addTagsToFile,
  uploadRequestedList,
} from "./api";
import { useQuery } from "react-query";
import success from "../imgs/success-cropped.svg";
import profile from "../imgs/profile-cropped.svg";
import Card from "./OptionCard";
import Header from "./Header";
import WalkThroughCard from "./WalkthroughCard";
import final from "../imgs/SkiptraceFinal-cropped.svg";
import "./Multistep.css";

export default function RequestedListUpload() {
  const stepperRef = useRef(null);
  const warning = useRef(null);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [titleUpload, setTitleUpload] = useState(
    "Upload any list in csv or xlsx format"
  );
  const [tagsList, setTagsList] = useState(false);
  const [upload_date, setUploadDate] = useState("");
  const [uploaded_file, setUploadedFile] = useState(null);
  const [file_type, setFileType] = useState("CSV");
  const [original_name, setOriginalName] = useState("");
  const [selections, setSelections] = useState({});
  const [activeIdx, setActiveIdx] = useState(0);
  const [progress, setProgress] = useState(0);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [client, setClient] = useState("");
  const [clientDone, setClientDone] = useState(false);
  const [clientFetch, setClientFetch] = useState(false);
  const [getTags, setGetTags] = useState(false);
  const [current_version, setCurrentVersion] = useState(1);
  const [file_size, setFileSize] = useState(0.0);
  const [mappings, setMappings] = useState({});
  const [approved, setApproved] = useState(false);
  const [getCol, setGetCol] = useState(false);
  const interval = useRef(null);
  //get all tags in the system
  const { data: globalTags } = useQuery("fetchTags", fetchTags, {
    cacheTime: 1000 * 60 * 30,
  });
  //get clients
  const { data: clients, isLoading: clientsLoading } = useQuery(
    "fetchClients",
    fetchClients,
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
    }
  );
  //when client is selected fetch his camapaign and tags
  useEffect(() => {
    if (client) {
      setClientFetch(true);
      setGetTags(true);
    }
  }, [client]);
  //handle  tags selection
  useEffect(() => {
    if (Object.keys(selections).length === 0) {
      setTagsList(false);
    }

    if (Object.keys(selections).length > 0) {
      setTagsList(true);
    }
  }, [selections]);
  //fetch campaign id
  const { data: campaigns } = useQuery(
    ["fetchCampaign", client.client_id],
    () => fetchCampaign(client.client_id),
    {
      enabled: clientFetch,
      onSuccess: () => {
        setClientFetch(false);
        console.log("camapaings are here");
      },
      cacheTime: 1000 * 60 * 30,
    }
  );
  //upload file to the client
  const uploadFormData = async () => {
    const formData = new FormData();
    formData.append("client_id", client.client_id);
    formData.append("cold_calling", true);
    formData.append("texting", true);
    formData.append("source", "RES");
    formData.append("list_pull_date", upload_date);
    formData.append("skip_source", "RES Skip Source");
    formData.append("skip_date", upload_date);
    formData.append("current_version", current_version);
    formData.append("upload_date", upload_date);
    formData.append("campaign_id", campaigns[0].campaign_id);
    formData.append("uploaded_file", uploaded_file);
    formData.append("file_type", file_type);
    formData.append("file_size", file_size);
    formData.append("original_name", original_name);
    formData.append("ready", true);
    formData.append("mappings", JSON.stringify(mappings));
    formData.append("description", "");
    const fileName = `${
      client.first_name
    }-CC-Texting'-${"RES"}-${upload_date}-${"RES Skip Source"}-${upload_date}-${original_name}-v${current_version}.${file_type}`;
    formData.append("file_name", fileName);
    let _val = progress;
    setProgress(1);
    const response = await uploadRequestedList(
      formData,
      _val,
      setProgress,
      setUploadSuccessful
    );
    clearInterval(interval.current);
    interval.current = null;
    console.log("Upload successful:", response.data);
    const formDataFile = new FormData();
    formDataFile.append("client_id", client.client_id);
    formDataFile.append("file_id", response.data.file_id);
    formDataFile.append("tags", Object.values(selections).join(","));
    console.log(selections);
    const response2 = await addTagsToFile(formDataFile);
    if (response.success && response2.success) {
      notify();
    } else {
      showErorr();
    }
  };
  //fetch client tags
  const { data: clientTags } = useQuery(
    ["fetchClientTags", client.client_id],
    () => fetchClientTags(client.client_id),
    {
      enabled: getTags,
      onSuccess: () => {
        setGetTags(false);
      },
      cacheTime: 1000 * 60 * 5,
    }
  );
  //Warning in case clicking next without entering required data
  const showWarn = () => {
    warning.current.show({
      severity: "warn",
      summary: "Warning",
      detail: " Please Enter Required Data To Proceed",
      life: 3000,
    });
  };
  //notification when upload is successful
  const notify = () => {
    toast.success(
      "The file has been successfully processed. You can now view the details in the database",
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };
  //notification when there is an error
  const showErorr = () => {
    toast.error(
      "There was an issue uploading the file. Please try again later. If the problem continues, contact the engineering team for assistance.",
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      }
    );
  };
  // Handle clicking on next
  const handleNextClick = (current_step, state, ref) => {
    if (state) {
      ref.current.nextCallback();
      setActiveIdx((prev) => prev + 1);
    } else {
      if (current_step === "mapping") {
        warning.current.show({
          severity: "warn",
          summary: "Warning",
          detail: " Please Confirm The Mapping To Proceed ",
          life: 3000,
        });
      } else {
        showWarn();
      }
    }
  };
  //Handle Upload file
  const handleFileUpload = (file) => {
    // TODO: adjust file types + excel files because they cause an error
    const validTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!validTypes.includes(file.type)) {
      warning.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "File type not supported. Please upload a CSV or Excel file.",
        life: 3000,
      });
      return;
    }
    if (file.type === "text/csv") {
      setFileType("csv");
    } else if (file.type === "application/vnd.ms-excel") {
      setFileType("xls");
    } else {
      setFileType("xlsx");
    }
    setUploadedFile(file);
    const nameTrimmed = file.name.split(".").slice(0, -1).join(".");
    setOriginalName(nameTrimmed);
    setFileSize(file.size);
    setCurrentVersion(1);
    setUploadDate(new Date().toISOString().split("T")[0]);
    setTitleUpload("Your file is uploaded successfully");
    setShowSuccessMessage(true);
  };
  //handle close button of success message
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  return (
    <div>
      <Header
        username={""}
        profileLink="/options"
        profileImage={profile}
        upload={true}
      />
      {showSuccessMessage && (
        <Dialog
          header="Success"
          visible={showSuccessMessage}
          onHide={closeSuccessMessage}
        >
          <div className="flex flex-col justify-center">
            <div className="alert alert-success">
              File {uploaded_file.name} uploaded successfully!
            </div>
          </div>
        </Dialog>
      )}
      <div className="flex flex-col min-h-svh gap-12">
        <Stepper ref={stepperRef} linear style={{ flexBasis: "50rem" }}>
          <StepperPanel header="Client Name">
            <div>
              <WalkThroughCard
                dropDowns={true}
                menus={[
                  {
                    name: "Clients's name",
                    placeholder: "Enter Client Name",
                    option: "full_name",
                  },
                ]}
                drop_options={[clients]}
                disable={[false]}
                onSet={setClientDone}
                functions={[setClient]}
                fetching={[clientsLoading]}
                onChoice={setClientDone}
                activeIdx={0}
                totalIdx={4}
                question={
                  <span className="text-center font-semibold">
                    Select the client for whom the list is intended
                  </span>
                }
              />

              <Toast ref={warning} />
              <div className="flex pt-4 justify-content-end">
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => {
                    handleNextClick("client", clientDone, stepperRef);
                  }}
                />
              </div>
            </div>
          </StepperPanel>
          <StepperPanel header="Upload File">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  totalIdx={4}
                  title={titleUpload}
                  upload={true}
                  image={success}
                  uploadedFile={uploaded_file}
                  onFileUpload={handleFileUpload}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                  setClientDone(false);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("file", uploaded_file, stepperRef);

                  setGetCol(true);
                  setApproved(false);
                }}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Mapping ">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Mapping
                  file={uploaded_file}
                  onMapped={setApproved}
                  approved={approved}
                  fetch={getCol}
                  setFetch={setGetCol}
                  stepperRef={stepperRef}
                  mapping={setMappings}
                  client={client.client_id}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick("mapping", approved, stepperRef)}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="List Tags">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  totalIdx={4}
                  title={
                    "Select the tags you want to add to the list. Any presets the client has already chosen will be automatically applied"
                  }
                  tags={true}
                  clientTags={clientTags}
                  input={true}
                  vertical={true}
                  sources={globalTags}
                  selections={setSelections}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                disabled
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("tags", tagsList, stepperRef);
                  uploadFormData();
                }}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Final Step">
            <div>
              <WalkThroughCard
                final={true}
                activeIdx={activeIdx}
                totalIdx={4}
                question={
                  <span>
                    {uploadSuccessful
                      ? "Congratulations! The file list successfully uploaded. We are currently breaking down the file into records and will notify you once the process is complete.Would you like to upload another list?"
                      : "Please wait the file is uploading"}
                  </span>
                }
                destination={"/data-options"}
                ProgressBar={true}
                progress={progress}
                uploadSuccessful={uploadSuccessful}
                finalButton={"Upload Another List"}
                img={final}
              />

              <Toast ref={warning} />

              <div className="flex pt-4 justify-content-end">
                <Button
                  label="Next"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => {
                    navigate("/data-options");
                  }}
                />
              </div>
            </div>
          </StepperPanel>
        </Stepper>
      </div>
    </div>
  );
}
