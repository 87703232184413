import { useEffect, useRef } from "react";

function useSessionTimeout(
  logoutCallback,
  role,
  isLoggedIn,
  timeoutDuration = 1200000
) {
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    if (role !== "user" || !isLoggedIn) {
      return; // Exit early if role is not 'user' or user is not logged in
    }

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimeout = () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        logoutCallback();
      }, timeoutDuration);
    };

    const setupEvents = () => {
      events.forEach((event) => window.addEventListener(event, resetTimeout));
    };

    const cleanupEvents = () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );
    };

    setupEvents();
    resetTimeout();

    return () => {
      cleanupEvents();
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [logoutCallback, role, isLoggedIn, timeoutDuration]);

  return null;
}

export default useSessionTimeout;
