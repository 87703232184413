import React from "react";
import Header from "./Header";
import profile from "../imgs/profile-cropped.svg";
import Card from "./OptionCard";
import newImg from "../imgs/new-cropped.svg";
import oldImg from "../imgs/old-cropped.svg";

const UploadFile = () => {
  return (
    <div>
      <Header
        username={localStorage.getItem("firstName")}
        profileLink="/options"
        profileImage={profile}
        options={true}
        file={false}
      />
      <h2 className="flex  ml-4 mb-1 text-lg font-bold">
        Can you tell us in which bucket does your list belong?
      </h2>

      <div className="flex gap-4 justify-center p-5 grid">
        <Card
          image={newImg}
          title="This is a brand new list I just pulled."
          buttonText="Proceed"
          destination={"/upload"}
        />
        <Card
          image={oldImg}
          title="This is a newer skipped version of an old list."
          buttonText="Select the older version"
          selectOld={true}
        />
      </div>
    </div>
  );
};

export default UploadFile;
