import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { ToggleButton } from 'primereact/togglebutton';
import { heightMap } from '@primer/react/lib-esm/Overlay/Overlay';

export default function PreferenceScreen() {
    const containerStyle = {
        // display: 'flex',
        justifyContent: 'center',
        marginTop: '5%',
        marginLeft: '25%',
        alignItems: 'center',
        height: '100vh',
        fontFamily: 'Open-Sauce, sans-serif',
    };

    const cardStyle = {
        width: '70%',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#f0f0f0', // change this to your desired background color
    };
    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px'
    };

    const buttonStyle = {
        width: '48%',
        padding: '10px',
        textAlign: 'center',
        borderRadius: '8px',
        backgroundColor: '#b3e5fc',
        color: '#000',
        border: 'none'
    };

    const toggleButtonStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    };

    return (
        <div style={containerStyle}>
            <p className="m-0" style={{ fontSize: '1em', marginBottom: '10%'}}>
                This is a one-time setup to know your preferences, it will be applied every time you upload a list. You can change it anytime.
            </p>
            <Card style={cardStyle}>
                <div style={{ fontSize: '1em', color: '#666' }}>
                    If we find different incomplete records for the same Property Address, which option do you prioritize?
                </div>
                <div style={buttonContainerStyle}>
                    <Button label="Keep record with Mailing Address, deprioritize Full Name if not available." style={buttonStyle} />
                    <Button label="Keep record with Full Name, I do not care about Mailing Address this much." style={buttonStyle} />
                </div>
                <div style={toggleButtonStyle}>
                    <i className="pi pi-angle-right" style={{ fontSize: '2em', color: '#2196f3' }}></i>
                </div>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <a href="#" style={{ color: '#2196f3', textDecoration: 'none', fontSize: '1em' }}>Skip now, complete later</a>
                </div>
            </Card>
        </div>
    );
}
