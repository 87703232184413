import React from 'react';
import './NotFoundScreen.css';
import notfound from '../imgs/error.svg';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <img src={notfound} alt="404 Not Found" className="not-found-image" />
      </div>
    </div>
  );
};

export default NotFound;
