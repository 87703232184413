import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingScreen from "./components/LandingScreen";
import PreferenceScreen from "./components/PreferenceScreen";
import NotFoundScreen from "./components/NotFoundScreen";
import Table from "./components/Table";
import File from "./components/File";
import OptionsScreen from "./components/OptionsScreen";
import MultiStep from "./components/Multistep";
import MultistepNewVersion from "./components/MultistepNewVersion";
import SignInScreen from "./components/SignInScreen";
import RegisterScreen from "./components/RegisterScreen";
import { AuthProvider } from "./components/AuthContext";
import ListCriteria from "./components/ListCriteria";
import PrivateRoute from "./components/PrivateRoute";
import UploadFile from "./components/UploadFile";
import SetUp from "./components/SetUp";
import SkipService from "./components/SkipService";
import UnauthorizedScreen from "./components/UnauthorizedScreen";
import DataOptions from "./components/DataOptions";
import SkipUpload from "./components/SkipUpload";
import MultistepSkipTracing from "./components/MultistepSkipTracing";
import WorkerTable from "./components/WorkerTable";
import DialerStats from "./components/DialerStats";
import RequestedListUpload from "./components/RequestedListUpload";
import ProcessedFileUpload from "./components/ProcessedFileUpload";
import CampaignEdit from "./components/CampaignName";
import HubSpotForm from "./components/Form";
import ChangePassword from "./components/ChangePassword";
import "./index.css";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingScreen />} />
          <Route path="/about" element={<PreferenceScreen />} />
          <Route path="/error" element={<NotFoundScreen />} />
          <Route path="/signin" element={<SignInScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route
            path="/edit-campaign"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <CampaignEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/database"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <Table user={true} />
              </PrivateRoute>
            }
          />
          <Route
            path="/contact-us"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <HubSpotForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/password"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/list-criteria"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <ListCriteria />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-stats"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <DialerStats />
              </PrivateRoute>
            }
          />
          <Route
            path="/res-database"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <Table worker={true} />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-processed-list"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <ProcessedFileUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/file"
            element={
              <PrivateRoute requiredRole={["user", "worker"]}>
                <File />
              </PrivateRoute>
            }
          />
          <Route
            path="/SetUp"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <SetUp />
              </PrivateRoute>
            }
          />
          <Route
            path="/options"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <OptionsScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <MultiStep />
              </PrivateRoute>
            }
          />
          <Route
            path="/skiptrace-service"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <SkipService />
              </PrivateRoute>
            }
          />
          <Route
            path="/skiptrace-upload"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <MultistepSkipTracing />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-newer-version"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <MultistepNewVersion />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-options"
            element={
              <PrivateRoute requiredRole={["user"]}>
                <UploadFile />
              </PrivateRoute>
            }
          />
          <Route path="/unauthorized" element={<UnauthorizedScreen />} />
          <Route
            path="/data-options"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <DataOptions />
              </PrivateRoute>
            }
          />
          <Route
            path="/skip-upload"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <SkipUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/upload-requested-list"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <RequestedListUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/database/clients"
            element={
              <PrivateRoute requiredRole={["worker"]}>
                <WorkerTable />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
