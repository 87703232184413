import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import Header from "./Header";
import profile from "../imgs/profile-cropped.svg";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchFiveFiles } from "./api";
import "./Custom.css";

export default function Table(props) {
  const [index, setIndex] = useState(() => {
    const lastindex = sessionStorage.getItem("index");
    // console.log(sessionStorage.getItem("index"));

    return lastindex ? JSON.parse(lastindex) : 2;
  });
  const [totalPages, setTotalPages] = useState(() => {
    const pages = sessionStorage.getItem("totalPages");
    // console.log(sessionStorage.getItem("totalPages"));

    return pages ? JSON.parse(pages) : 1;
  });
  const [currentPage, setCurrentPage] = useState(() => {
    const page = sessionStorage.getItem("currentPage");
    // console.log(sessionStorage.getItem("currentPage"));

    return page ? JSON.parse(page) : 1;
  });
  const [totalFiles, setTotalFiles] = useState(() => {
    const total = sessionStorage.getItem("totalFiles");
    // console.log(sessionStorage.getItem("totalFiles"));

    return total ? JSON.parse(total) : 0;
  });
  const [noRows, setNoRows] = useState(() => {
    const rows = sessionStorage.getItem("noRows");
    // console.log(sessionStorage.getItem("noRows"));

    return rows ? JSON.parse(rows) : 0;
  });
  const toast = useRef(null);
  const [fetch, setFetch] = useState(true);
  const [fetchMoreData, setFetchMoreData] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  //show notication when there are no more files to load
  const showContrast = () => {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: "There are no more files to load",
      life: 2000,
    });
  };

  const navigate = useNavigate();
  const uploadedFileTemplate = (rowData) => {
    return (
      <>
        <Tooltip target=".download-link" position="top" />
        <a
          href={rowData.download_link}
          download
          className="download-link"
          data-pr-tooltip="Download File"
        >
          <i className="pi pi-download" style={{ marginRight: ".5em" }}></i>
          Download
        </a>
      </>
    );
  };
  const detailsTemplate = (rowData, { rowIndex }) => {
    const handleDetailsClick = () => {
      handleButton(rowIndex);
    };
    return (
      <button
        className="details rounded-full bg-blue-500 text-white flex justify-start items-start h-full p-2"
        onClick={handleDetailsClick}
      >
        Go to Details
      </button>
    );
  };
  //operations file template
  const fileBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.operations_file_download !== null ? (
          <>
            <Tooltip target=".download-link" position="top" />
            <a
              href={rowData.operations_file_download}
              download
              className="download-link"
              data-pr-tooltip="Download File"
            >
              <i className="pi pi-download" style={{ marginRight: ".5em" }}></i>
              {rowData.operations_file_name}
            </a>
          </>
        ) : (
          <>{<span> This File Requires Additional Processing</span>}</>
        )}
      </>
    );
  };
  const handleButton = (rowIndex) => {
    navigate("/file", {
      state: { fileID: files[rowIndex].file_id, data: files },
    });
  };
  //handle load more button whenpressed show loading progress and retrieve
  const handleLoadMore = () => {
    // Enable the query when the button is clicked
    setFetchMoreData(true);
  };
  // fetch 5 files initially
  const fetchFiles = async () => {
    const formData = new FormData();
    const key = props.user ? "client_id" : "worker_id";
    formData.append(key, localStorage.getItem("client_id"));
    const response = await fetchFiveFiles(formData);
    // setTotalPages(response.)
    setTotalPages(response.total_number_of_pages);
    sessionStorage.setItem(
      "totalPages",
      JSON.stringify(response.total_number_of_pages)
    );
    setTotalFiles(response.total_number_of_records);
    sessionStorage.setItem(
      "totalFiles",
      JSON.stringify(response.total_number_of_records)
    );
    sessionStorage.setItem("noRows", JSON.stringify(response.data.length));

    return response.data;
  };
  const {
    data: files,
    isLoading: filesLoading,
    isFetching: filesFetching,
  } = useQuery("fetchFiles", fetchFiles, {
    enabled: fetch,
    onSuccess: () => {
      setFetch(!fetch);
      setIndex(2);
      sessionStorage.removeItem("index");
      sessionStorage.removeItem("currentPage");
    },
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 20,
  });
  if (filesLoading || filesFetching) {
    sessionStorage.removeItem("index");
    sessionStorage.removeItem("currentPage");
  }
  //fetch more files
  const fetchMore = async () => {
    const formData = new FormData();
    const key = props.user ? "client_id" : "worker_id";
    formData.append(key, localStorage.getItem("client_id"));
    formData.append("page_number", index);
    setIndex(index + 1);
    sessionStorage.setItem("index", JSON.stringify(index + 1));
    const response = await fetchFiveFiles(formData);
    setNoRows(response.data.length);
    return response.data;
  };

  const { data: moreFiles, isFetching: moreFilesFetching } = useQuery(
    "fetchMore",
    fetchMore,
    {
      enabled: fetchMoreData,
      onSuccess: () => {
        setFetchMoreData(false);
        setLoaded(!loaded);
      },
      cacheTime: 1000 * 60 * 20,
    }
  );
  //use efffect to append data whenever the load more button is presses
  useEffect(() => {
    setFetch(!fetch);
    if (initialRender) {
      setInitialRender(false);
    } else {
      if (moreFiles) {
        if (currentPage === totalPages) {
          showContrast();
        } else {
          files.push(...moreFiles);
          setNoRows(files.length);
          setCurrentPage(currentPage + 1);
          sessionStorage.setItem(
            "currentPage",
            JSON.stringify(currentPage + 1)
          );
        }
      }
    }
  }, [loaded]);

  //load more button at the end ofthe table
  const customPaginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport ",

    CurrentPageReport: (options) => {
      return (
        <span style={{ margin: "0 5px" }}>
          Page {currentPage} of {totalPages} loaded.
        </span>
      );
    },
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <>
        {(rowData.status === "Skip Traced & Pending Upload To Dialer" ||
          (rowData.skiptraced_by_res && rowData.savings > 0)) && (
          <>
            <span className="flex items-center ">
              You saved ${rowData.savings}{" "}
              <i className="pi pi-money-bill px-2"></i>
            </span>
            <br />
          </>
        )}
        <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
      </>
    );
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };
  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };
  const [statuses] = useState([
    "Pending Dialer Upload",
    "Uploaded To Dialer",
    "Pending Cleaning",
    "Pending Skip Tracing",
    "Skip Traced & Pending Upload To Dialer",
    "Cleaned & Pending Upload To Dialer",
  ]);
  const getSeverity = (status) => {
    switch (status) {
      case "Pending Cleaning":
        return "danger";

      case "Uploaded To Dialer":
        return "success";

      case "Pending Skip Tracing":
        return "info";

      case "Pending Dialer Upload":
        return "warning";

      case "Cleaned & Pending Upload To Dialer":
        return "help";

      case "Skip Traced & Pending Upload To Dialer":
        return null;
    }
  };
  const footer = (
    <div className="flex flex-col items-center text-center justify-center space-y-4">
      {moreFilesFetching && (
        <div>
          <ProgressSpinner
            style={{ width: "20px", height: "20px", margin: "12px" }}
          />
        </div>
      )}

      <Button
        className="loadMore rounded-full bg-blue-500 text-white flex justify-center items-center h-full p-2 w-1/2"
        onClick={handleLoadMore}
        disabled={moreFilesFetching}
      >
        Load More
      </Button>
    </div>
  );
  const header = (
    <div className="flex flex-wrap items-center justify-end gap-2">
      <span className="text-sm text-900 font-bold ml-auto">
        {noRows} of {totalFiles} files
      </span>
    </div>
  );
  return (
    <div>
      {props.user && (
        <Header
          username={localStorage.getItem("firstName")}
          profileLink="/options"
          profileImage={profile}
          options={false}
        />
      )}
      {!filesLoading ? (
        <div>
          <Toast ref={toast} />
          {props.user && (
            <>
              <InputText
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "1rem",
                  width: "100%",
                }}
                onInput={(e) =>
                  setFilters({
                    ...filters,
                    global: {
                      value: e.target.value,
                      matchMode: FilterMatchMode.CONTAINS,
                    },
                  })
                }
                placeholder="Search your RES Storage..."
                className="p-inputtext-lg"
                size={40}
              />
              <DataTable
                value={files}
                sortMode="multiple"
                filters={filters}
                footer={footer}
                header={header}
                paginator
                paginatorTemplate={customPaginatorTemplate}
                rows={10}
                rowsPerPageOptions={10}
                style={{ margin: "1rem" }}
                filterDisplay="row"
                globalFilterFields={["status", "file_name", "upload_date"]}
              >
                <Column field="file_name" header="Filename"></Column>
                <Column
                  field="status"
                  header="Status"
                  showFilterMenu={false}
                  filterMenuStyle={{ width: "14rem" }}
                  style={{ minWidth: "12rem" }}
                  body={statusBodyTemplate}
                  filter
                  filterElement={statusRowFilterTemplate}
                />
                <Column
                  field="upload_date"
                  header="Date Uploaded"
                  sortable
                ></Column>

                <Column body={detailsTemplate} header="Details"></Column>
                <Column header="Options" body={uploadedFileTemplate}></Column>
              </DataTable>
            </>
          )}

          {props.worker && (
            <>
              <InputText
                type="search"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "1rem",
                  width: "100%",
                }}
                onInput={(e) =>
                  setFilters({
                    ...filters,
                    global: {
                      value: e.target.value,
                      matchMode: FilterMatchMode.CONTAINS,
                    },
                  })
                }
                placeholder="Search ..."
                className="p-inputtext-lg"
                size={40}
              />
              <DataTable
                value={files}
                sortMode="multiple"
                filters={filters}
                footer={footer}
                header={header}
                paginator
                paginatorTemplate={customPaginatorTemplate}
                rows={10}
                rowsPerPageOptions={1}
                filterDisplay="row"
                globalFilterFields={[
                  "status",
                  "file_name",
                  "client_name",
                  "upload_date",
                ]}
              >
                <Column field="client_name" header="Client Name"></Column>
                <Column field="file_name" header="File Name"></Column>
                <Column
                  field="status"
                  header="Status"
                  showFilterMenu={false}
                  filterMenuStyle={{ width: "14rem" }}
                  style={{ minWidth: "12rem" }}
                  body={statusBodyTemplate}
                  filter
                  filterElement={statusRowFilterTemplate}
                />
                <Column
                  field="upload_date"
                  header="Date Uploaded"
                  sortable
                ></Column>
                <Column
                  body={fileBodyTemplate}
                  field="operations_file_name"
                  header="Operations File Name"
                ></Column>
              </DataTable>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center space-y-4">
          <h2 className="m-4">Please wait we're retrieving your data</h2>
          <ProgressSpinner
            style={{ width: "100px", height: "100px", margin: "12px" }}
          />
        </div>
      )}
    </div>
  );
}
